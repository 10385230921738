import React, { useState, useContext } from 'react';
import UserContext from './context/UserContext';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './css/components.css';
import TrainingTableRowEdit from './TrainingTableRowEdit';
import TrainingTableRowDelete from './TrainingTableRowDelete';

export default function TrainingTableRow ({ item, trainingCategories, refreshTrainingList, mediaTypes }) {
    const { user } = useContext(UserContext);

    const getMediaTypeIcon = () => {
        if (mediaTypes === null) return "fas fa-spinner fa-spin"
        return mediaTypes.find((medium) => medium.media_type_name === item.media_type_name).media_type_icon;
    }

    return(
        <>
            <tr>
                <td valign='middle'><a href={item.training_url} target="_blank">{item.training_name}</a></td>
                <td className='hidden__on__mobile' valign='middle'>{item.training_category_name}</td>
                <td valign='middle'><div className="flex__box__center"><i className={`${getMediaTypeIcon()}`} title={item.media_type_name}></i></div></td>
                {
                    user.role_id >= 8 &&
                    <td valign='middle'>
                        <div className="flex__box__center">
                            {
                                trainingCategories === null || mediaTypes === null ?
                                <i className="fas fa-spinner fa-spin"></i> :
                                <TrainingTableRowEdit 
                                    item={item} 
                                    trainingCategories={trainingCategories} 
                                    mediaTypes={mediaTypes}
                                    refreshTrainingList={refreshTrainingList} 
                                />
                            }
                            <TrainingTableRowDelete
                                item={item}
                                refreshTrainingList={refreshTrainingList}
                            />
                        </div>
                    </td>
                }
                
            </tr>
        </>
    )
}