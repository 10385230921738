import { graphConfig } from "./authConfig";

/**
 * Attaches a given access token to a Microsoft Graph API call. Returns information about the user
 */
export async function callMsGraph(accessToken, endpoint) {
    const headers = new Headers();
    headers.append('Authorization', `Bearer ${accessToken}`);
    headers.append('Content-Type', 'application/json');

    const response = await fetch(`https://graph.microsoft.com/v1.0/${endpoint}`, { headers });

    // Determine if the endpoint is for binary data (e.g., photo) or JSON
    if (endpoint.includes('/photo/$value')) {
        if (!response.ok) {
            throw new Error(`Error fetching data from Microsoft Graph: ${response.statusText}`);
        }
        return response.blob(); // Handle binary data
    } else {
        if (!response.ok) {
            throw new Error(`Error fetching data from Microsoft Graph: ${response.statusText}`);
        }
        return response.json(); // Handle JSON data
    }
}