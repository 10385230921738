// App.js

import React, { useContext } from 'react';
import { PageLayout } from './components/PageLayout';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router } from 'react-router-dom';

import UserContext from './components/context/UserContext';
import Home from './components/Home';
import { ServiceOrderProvider } from './components/context/ServiceOrderContext';
import { PartsProvider } from './components/context/PartsContext';

// Import NavLinks
import NavLinks from './components/NavLinks';

function App() {
  const { user } = useContext(UserContext);

  return (
    <Router>
      <PageLayout>
        <AuthenticatedTemplate>
          {user !== null ? (
            // Wrap NavLinks with ServiceOrderProvider and PartsProvider
            <ServiceOrderProvider>
              <PartsProvider>
                <NavLinks />
              </PartsProvider>
            </ServiceOrderProvider>
          ) : (
            <Home />
          )}
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <p>You are not signed in! Please sign in with your Microsoft account.</p>
        </UnauthenticatedTemplate>
      </PageLayout>
    </Router>
  );
}

export default App;
