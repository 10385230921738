import React from 'react';
import './css/components.css';

export default function NoteCreate({ title_textHandler, body_textHandler, saveHandler, title_text, body_text }) {
    const isOpen=true;
    return (
        <div>
            <div className="note">
                <input 
                    placeholder='Enter title...' 
                    value={title_text}
                    onChange={title_textHandler}  
                />
                <textarea
                    cols='10'
                    rows='5'
                    placeholder='Enter text...'
                    
                    value={body_text}
                    onChange={body_textHandler}
                />
                <hr className='note__separator'/>
                <div className="note__footer">
                    <span className="label note__written__by"> left</span>
                    <button className="btn btn-primary" onClick={saveHandler}>Save</button>
                </div>
            </div>
        </div>
    )
}