import React, { useState, useEffect, useContext, useRef } from 'react';
import './css/appointments.css'; // Existing CSS
import DOMPurify from 'dompurify'; // Import DOMPurify for sanitizing HTML
import { unescape } from 'lodash'; // Import unescape from lodash

/* If you use CSS */
import 'react-pro-sidebar/dist/css/styles.css'; // Ensure you're using react-pro-sidebar@0.7.1
import '@fortawesome/fontawesome-free/css/all.min.css';

import UserContext from './context/UserContext';
import PartsContext from './context/PartsContext';
import Loading from './Loading';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Link, useNavigate } from 'react-router-dom'; // Import Link for breadcrumbs and useNavigate
import { Table, Card, Row, Col } from 'react-bootstrap'; // Import Card, Row, Col for layout
import { ProSidebar, Menu, MenuItem } from 'react-pro-sidebar'; // Updated import for version 0.7.1
import {
  FaRegStickyNote,
  FaCamera,
  FaTools,
  FaTruck, // Import the truck icon
  FaBars, // Import the bars icon for the toggle button
} from 'react-icons/fa'; // Ensure react-icons is installed
import SignatureCanvas from 'react-signature-canvas'; // Import SignatureCanvas
import Form from 'react-bootstrap/Form'; // Added import for Form component
import SignaturePad from 'signature_pad';

const STATUSES = ['Dispatched', 'Depart', 'Arrive', 'Complete'];

export default function AppointmentWorkFlow() {
  // **Move all useState declarations to the top**
  const { user } = useContext(UserContext);
  const { parts, setParts } = useContext(PartsContext);
  const SPECIAL_PART_NUMBERS = ['3272', '115', '3292', '3288', '6155'];
  const [currentStatus, setCurrentStatus] = useState(STATUSES[0]);
  const [notes, setNotes] = useState('');
  const [signature, setSignature] = useState(null);
  const sigCanvas = useRef({});
  const [selectedAppointment, setSelectedAppointment] = useState(null);

  // **Ensure activeSection is declared here before usage**
  const [activeSection, setActiveSection] = useState('status');

  // New state variables for Customer Notes
  const [customerNotes, setCustomerNotes] = useState('Loading customer notes...');
  const [customerNotesLoading, setCustomerNotesLoading] = useState(false);
  const [customerNotesError, setCustomerNotesError] = useState(null);

  // State variables for fetched appointments, service orders, loading, and error handling
  const [appointments, setAppointments] = useState([]);
  const [serviceOrders, setServiceOrders] = useState([]);
  const [appointmentsLoading, setAppointmentsLoading] = useState(false);
  const [serviceOrdersLoading, setServiceOrdersLoading] = useState(false);
  const [error, setError] = useState(null);

  // State variables for parts modal
  const [showPartsModal, setShowPartsModal] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedPart, setSelectedPart] = useState(null);
  const [quantity, setQuantity] = useState('');
  const [price, setPrice] = useState('');
  const [cost, setCost] = useState('');
  const [partsLoading, setPartsLoading] = useState(false);
  const [partsError, setPartsError] = useState(null);

  // Breadcrumbs State
  const breadcrumbItems = [
    { name: 'Appointments', link: '/appointments' },
    {
      name: selectedAppointment
        ? `Appointment #${selectedAppointment.AppointmentNumber}`
        : 'Details',
    },
  ];

  // API details (Replace with environment variables later)
  const GET_APPOINTMENTS_ENDPOINT =
    'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_get_appointments';
  const GET_SERVICE_ORDERS_ENDPOINT =
    'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_get_acu_specific_so';
  const UPDATE_APPOINTMENT_ENDPOINT =
    'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_update_appointment';
  const GET_ALL_ITEMS_ENDPOINT =
    'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_get_all_items';
  const ADD_ITEM_TO_ACUMATICA_ENDPOINT =
    'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_add_item_to_acu_SO'; // Corrected API Endpoint
  const ADD_IMAGES_ENDPOINT =
    'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_add_items_acu_so'; // Image Upload Endpoint
  const GET_THUMBNAILS_ENDPOINT =
    'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_get_so_images'; // Thumbnails Fetch Endpoint
  const GET_CUSTOMER_DETAILS_ENDPOINT =
    'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_get_customer_details';
  const API_KEY =
    '3XnwNPAWjV5bVyTEZ83u24cj3PRCA1zz44nifSQz'; // Replace with your actual API key or use environment variables

  const [searchTerm, setSearchTerm] = useState(''); // State for search term

  // Filter parts based on the search term, checking both itemID and itemDescription
  const filteredParts = parts.filter(
    (part) =>
      part.itemID.toLowerCase().includes(searchTerm.toLowerCase()) ||
      part.itemDescription.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // State variables for detail items
  const [detailItems, setDetailItems] = useState([]);
  const [detailItemsLoading, setDetailItemsLoading] = useState(false);
  const [detailItemsError, setDetailItemsError] = useState(null);

  // New Endpoint for Detail Items
  const GET_DETAIL_ITEMS_ENDPOINT =
    'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_get_detail_items_for_SO';

  /**
   * Fetch detail items for a given service order number
   * @param {string} serviceOrderNumber - The service order number (e.g., "FS000002")
   */
  const fetchDetailItems = async (serviceOrderNumber) => {
    setDetailItemsLoading(true);
    setDetailItemsError(null);
    console.log('Starting fetchDetailItems...');

    try {
      const response = await fetch(GET_DETAIL_ITEMS_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY, // Reusing the existing API key
        },
        body: JSON.stringify({ serviceOrderNumber }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error(`API Error Response: ${errorText}`);
        throw new Error(`API responded with status ${response.status}`);
      }

      const data = await response.json();
      console.log('Fetched Detail Items:', data);

      if (Array.isArray(data)) {
        // Assuming the response is an array with one object containing 'details'
        const detailData = data[0]?.details || [];
        setDetailItems(detailData);
      } else {
        console.warn('No detail items found in the response.');
        setDetailItems([]);
      }
    } catch (err) {
      console.error('Error fetching detail items:', err);
      setDetailItemsError(
        'Failed to load detail items. Please try again later.'
      );
    } finally {
      setDetailItemsLoading(false);
      console.log('fetchDetailItems completed.');
    }
  };

  /**
   * Trigger fetchDetailItems when a new appointment is selected
   */
  useEffect(() => {
    if (selectedAppointment) {
      const serviceOrderNumber = selectedAppointment.ServiceOrderID;
      if (serviceOrderNumber) {
        fetchDetailItems(serviceOrderNumber);
      } else {
        setDetailItems([]);
      }
    } else {
      setDetailItems([]);
    }
  }, [selectedAppointment]);

  /**
   * Define the missing functions
   */
  const handleNextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handleBackStep = () => {
    setCurrentStep((prevStep) => (prevStep > 1 ? prevStep - 1 : prevStep));
  };

  /**
   * Fetch appointments from the Lambda function using POST request
   * @param {string} TechnicianEmail - The email of the logged-in user
   */
  const fetchAppointments = async (TechnicianEmail) => {
    setAppointmentsLoading(true);
    setError(null);
    console.log('Starting fetchAppointments...');

    try {
      const response = await fetch(GET_APPOINTMENTS_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY, // Include the API key in headers
        },
        body: JSON.stringify({
          TechnicianEmail,
          Statuses: [
            'Dispatched',
            'Depart',
            'Arrive',
            'Complete',
            'Canceled',
          ],
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error(`API Error Response: ${errorText}`);
        throw new Error(`API responded with status ${response.status}`);
      }

      const data = await response.json();
      console.log('Fetched Appointments:', data); // Log the raw appointments data

      if (data.data) {
        // Log each appointment's VisitID
        data.data.forEach((appointment, index) => {
          console.log(`Appointment ${index + 1} VisitID:`, appointment.VisitID);
        });

        setAppointments(data.data);
      } else {
        console.warn('No appointments found in the response.');
        setAppointments([]);
      }
    } catch (err) {
      console.error('Error fetching appointments:', err);
      setError('Failed to load appointments. Please try again later.');
    } finally {
      setAppointmentsLoading(false);
      console.log('fetchAppointments completed.');
    }
  };

  /**
   * Fetch service orders based on appointments
   * @param {Array} serviceOrderIDs - Array of ServiceOrderID from appointments
   */
  const fetchServiceOrders = async (serviceOrderIDs) => {
    setServiceOrdersLoading(true);
    setError(null);
    console.log('Starting fetchServiceOrders...');

    try {
      const response = await fetch(GET_SERVICE_ORDERS_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY, // Include the API key in headers
        },
        body: JSON.stringify({ serviceOrderIDs }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error(`API Error Response: ${errorText}`);
        throw new Error(`API responded with status ${response.status}`);
      }

      const data = await response.json();
      console.log('Fetched Service Orders:', data); // Log the raw service orders data

      // Parse the response body
      let serviceOrdersArray = [];

      if (data.body) {
        serviceOrdersArray = JSON.parse(data.body);
        console.log('Parsed Service Orders Body:', serviceOrdersArray); // Log parsed body
      } else if (data.data) {
        // In case Lambda returns data directly
        serviceOrdersArray = data.data;
        console.log('Parsed Service Orders Data:', serviceOrdersArray); // Log parsed data
      } else {
        // Handle unexpected response format
        throw new Error('Unexpected API response format');
      }

      if (Array.isArray(serviceOrdersArray) && serviceOrdersArray.length > 0) {
        setServiceOrders(serviceOrdersArray);

        // Optionally, log each service order's VisitID
        serviceOrdersArray.forEach((so, index) => {
          console.log(`Service Order ${index + 1} VisitID:`, so.VisitID);
        });

        // You can set a specific state if needed
        // For example, setting visitId for the first service order:
        if (serviceOrdersArray[0]?.VisitID) {
          setVisitId(serviceOrdersArray[0].VisitID);
          console.log('Set visitId:', serviceOrdersArray[0].VisitID);
        }
      } else {
        setServiceOrders([]);
        setError('No service orders found for the fetched appointments.');
      }
    } catch (err) {
      console.error('Error fetching service orders:', err);
      setError('Failed to load service orders. Please try again later.');
    } finally {
      setServiceOrdersLoading(false);
      console.log('fetchServiceOrders completed.');
    }
  };

  /**
   * Fetch parts from the Lambda function
   */
  const fetchParts = async () => {
    setPartsLoading(true);
    setPartsError(null);
    console.log('Starting fetchParts...');

    try {
      const response = await fetch(GET_ALL_ITEMS_ENDPOINT, {
        method: 'GET', // Changed to GET request
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY,
        },
        // Removed body as it's not needed for GET requests
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error(`API Error Response: ${errorText}`);
        throw new Error(`API responded with status ${response.status}`);
      }

      const data = await response.json();
      console.log('Fetched Parts:', data);

      // Assuming data is an array of parts
      if (Array.isArray(data)) {
        setParts(data);
      } else if (data.body) {
        const parsedData = JSON.parse(data.body);
        setParts(parsedData);
      } else {
        throw new Error('Unexpected API response format');
      }
    } catch (err) {
      console.error('Error fetching parts:', err);
      setPartsError('Failed to load parts. Please try again later.');
    } finally {
      setPartsLoading(false);
      console.log('fetchParts completed.');
    }
  };

  /**
   * Fetch customer details, including Customer Notes
   */
  const fetchCustomerDetails = async () => {
    if (!selectedAppointment) {
      console.warn('No appointment selected. Cannot fetch customer details.');
      return;
    }

    // Find the corresponding service order
    const serviceOrder = serviceOrders.find(
      (so) => so.serviceOrderNumber === selectedAppointment.ServiceOrderID
    );

    if (!serviceOrder) {
      console.warn('No service order found for the selected appointment.');
      setCustomerNotes('No customer notes available.');
      return;
    }

    const customerName = serviceOrder.customerName;

    setCustomerNotesLoading(true);
    setCustomerNotesError(null);

    try {
      const response = await fetch(GET_CUSTOMER_DETAILS_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY,
        },
        body: JSON.stringify({
          customerName, // Pass customerName as required by the API
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error(`API Error Response: ${errorText}`);
        throw new Error(`API responded with status ${response.status}`);
      }

      const data = await response.json();
      console.log('Fetched Customer Details:', data);

      if (
        data.customerDetails &&
        Array.isArray(data.customerDetails) &&
        data.customerDetails.length > 0
      ) {
        const customerNote =
          data.customerDetails[0]?.note?.value || 'No customer notes available.';
        setCustomerNotes(customerNote);
      } else {
        console.warn('No customer details found in the response.');
        setCustomerNotes('No customer notes available.');
      }
    } catch (err) {
      console.error('Error fetching customer details:', err);
      setCustomerNotesError(
        'Failed to load customer notes. Please try again later.'
      );
      setCustomerNotes('Error loading customer notes.');
    } finally {
      setCustomerNotesLoading(false);
      console.log('fetchCustomerDetails completed.');
    }
  };

  /**
   * Trigger fetchServiceOrders when appointments are fetched
   */
  useEffect(() => {
    if (appointments.length > 0) {
      const serviceOrderIDs = [
        ...new Set(
          appointments.map((appt) => appt.ServiceOrderID.replace('FS', ''))
        ),
      ];
      console.log('Fetching service orders for IDs:', serviceOrderIDs);
      (async () => {
        await fetchServiceOrders(serviceOrderIDs);
      })();
    }
  }, [appointments]);

  /**
   * Fetch parts when the modal is opened and parts list is empty
   */
  useEffect(() => {
    if (showPartsModal && parts.length === 0) {
      fetchParts();
    }
  }, [showPartsModal]);

  /**
   * Fetch appointments and then service orders when the component mounts
   */
  useEffect(() => {
    if (user && user.email) {
      console.log('User is logged in. Initiating appointment fetch...');
      (async () => {
        await fetchAppointments(user.email);
      })();
    } else {
      console.warn('User is not logged in or email is missing.');
    }
  }, [user]);

  /**
   * Fetch customer details when the Notes section is active and an appointment is selected
   */
  useEffect(() => {
    if (activeSection === 'notes' && selectedAppointment) {
      fetchCustomerDetails();
      refreshNotes(); // Add this line to refresh invoice and internal notes
    }
  }, [activeSection, selectedAppointment]);

  /**
   * Handle status change
   * @param {string} newStatus - The new status to set
   */
  const handleStatusChange = async (newStatus) => {
    if (!selectedAppointment) {
      console.error('No appointment selected.');
      return;
    }

    // Find the corresponding service order
    const serviceOrder = serviceOrders.find(
      (so) => so.serviceOrderNumber === selectedAppointment.ServiceOrderID
    );

    // Determine if the customer is Target and has a valid Visit ID
    const isTargetCustomer = serviceOrder?.customerName?.includes('Target');
    const hasValidVisitId = !!selectedAppointment.VisitID;

    // Prepare the payload for updating the appointment status
    const payload = {
      ServiceOrderID: selectedAppointment.ServiceOrderID,
      AppointmentNumber: selectedAppointment.AppointmentNumber,
      Status: newStatus,
    };

    console.log('Updating appointment with payload:', payload);

    try {
      // Update the appointment status using the normal endpoint
      const response = await fetch(UPDATE_APPOINTMENT_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error(`API Error Response: ${errorText}`);
        throw new Error(`API responded with status ${response.status}`);
      }

      const data = await response.json();
      console.log('Appointment updated successfully:', data);

      // Update the selected appointment's status in state
      setSelectedAppointment((prev) => ({
        ...prev,
        Status: newStatus,
      }));

      // Update the currentStatus state to reflect the new status
      setCurrentStatus(newStatus);

      // Update the appointments list to reflect the new status
      setAppointments((prevAppointments) =>
        prevAppointments.map((appt) =>
          appt.AppointmentNumber === selectedAppointment.AppointmentNumber
            ? { ...appt, Status: newStatus }
            : appt
        )
      );

      // Only update Target visit endpoint if customer is Target and Visit ID is valid
      if (isTargetCustomer && hasValidVisitId) {
        // **Update Target Visit Status**
        const UPDATE_TARGET_VISIT_ENDPOINT =
          'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_update_target_visit';

        // Prepare the payload for the Target endpoint
        let targetPayload = {};

        if (newStatus === 'Depart') {
          // En route status
          targetPayload = {
            visit_status: 'En route',
            estimated_time_arrival: new Date(Date.now() + 60 * 60 * 1000).toISOString(),
            visit_comments: 'The technician is on the way.',
            technician_info: user.email,
            visit_id: selectedAppointment.VisitID,
            visitor_phone: serviceOrder.contactPhone || '480-926-1975',
          };

          console.log('Sending En route status to Target endpoint with payload:', targetPayload);

          // Send the payload to the Target endpoint
          await fetch(UPDATE_TARGET_VISIT_ENDPOINT, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'x-api-key': API_KEY,
            },
            body: JSON.stringify(targetPayload),
          });
        } else if (newStatus === 'Arrive' || newStatus === 'Complete') {
          // Check-in or Check-out status
          targetPayload = {
            visit_status: newStatus === 'Arrive' ? 'Check-in' : 'Check-out',
            check_in_time: newStatus === 'Arrive' ? new Date().toISOString() : undefined,
            check_out_time: newStatus === 'Complete' ? new Date().toISOString() : undefined,
            visit_comments:
              newStatus === 'Arrive'
                ? 'The Technician has arrived and started work.'
                : 'The Technician has completed their visit.',
            visit_id: selectedAppointment.VisitID,
          };

          // Include geolocation if available
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
              async (position) => {
                const { latitude, longitude } = position.coords;
                console.log(`Geolocation for status "${newStatus}":`, {
                  latitude,
                  longitude,
                });
                if (newStatus === 'Arrive') {
                  targetPayload.check_in_latitude = latitude;
                  targetPayload.check_in_longitude = longitude;
                } else {
                  targetPayload.check_out_latitude = latitude;
                  targetPayload.check_out_longitude = longitude;
                }

                // Send the payload to the Target endpoint
                await fetch(UPDATE_TARGET_VISIT_ENDPOINT, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': API_KEY,
                  },
                  body: JSON.stringify(targetPayload),
                });
              },
              (error) => {
                console.error('Error obtaining geolocation:', error);
              }
            );
          } else {
            console.error('Geolocation is not supported by this browser.');
            await fetch(UPDATE_TARGET_VISIT_ENDPOINT, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'x-api-key': API_KEY,
              },
              body: JSON.stringify(targetPayload),
            });
          }
        }
      } else {
        console.log('Appointment does not meet criteria for updating Target visit.');
      }
    } catch (err) {
      console.error('Error updating appointment status:', err);
      setError('Failed to update appointment status. Please try again later.');
    }
  };

  /**
   * Handle selecting an appointment
   * @param {object} appointment - The appointment object selected
   */
  const handleSelectAppointment = (appointment) => {
    setSelectedAppointment(appointment);
    setCurrentStatus(appointment.Status);
  };

  /**
   * Handle returning to the appointments list
   */
  const handleBackToList = () => {
    setSelectedAppointment(null); // Clear the selected appointment
    navigate('/AppointmentWorkFlow'); // Navigate back to the list
  };

  /**
   * Handle saving all data (notes, signature, status)
   * This function should be implemented to send updated data back to the backend
   */
  const handleSaveData = () => {
    console.log('Saving data:', {
      notes,
      signature,
      currentStatus,
      selectedAppointment,
      invoiceNotes,
      internalNotes,
    });
    // Implement API call to save data
  };

  /**
   * Format a date string into MM/DD/YYYY format
   * @param {string} dateString - The date string to format
   * @returns {string} - The formatted date string
   */
  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const dateObj = new Date(dateString);
    if (isNaN(dateObj)) return 'Invalid Date';
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getDate()).padStart(2, '0');
    const year = dateObj.getFullYear();
    return `${month}/${day}/${year}`;
  };

  /**
   * Get the next status in the flow
   * @param {string} currentStatus - The current status
   * @returns {string|null} - The next status or null if at the end
   */
  const getNextStatus = (currentStatus) => {
    const currentIndex = STATUSES.indexOf(currentStatus);
    if (currentIndex === -1 || currentIndex === STATUSES.length - 1)
      return null;
    return STATUSES[currentIndex + 1];
  };

  /**
   * Render appointment details including customer information and description.
   */
  const renderAppointmentDetails = () => {
    if (!selectedAppointment) return null;

    // Find the corresponding service order
    const serviceOrder = serviceOrders.find(
      (so) => so.serviceOrderNumber === selectedAppointment.ServiceOrderID
    );

    const isComplete = selectedAppointment.Status === 'Complete';
    const isOnHold = selectedAppointment.OnHold === 1; // Ensure this is a boolean

    // Determine if the customer is Target
    const isTargetCustomer = serviceOrder?.customerName?.includes('Target');

    return (
      <div className="awf-detailsContent compact">
        {/* Customer Information Box */}
        <div className="awf-infoBox">
          {/* Customer */}
          <div className="awf-infoItem">
            <span className="awf-infoLabel">Customer</span>
            <span className="awf-infoValue">
              {serviceOrder?.customerName || 'N/A'}
            </span>
          </div>

          {/* Customer Order */}
          <div className="awf-infoItem">
            <span className="awf-infoLabel">Customer Order</span>
            <span className="awf-infoValue">
              {serviceOrder?.customerOrder || 'N/A'}
            </span>
          </div>

          {/* Visit ID - Only show if Target customer */}
          {isTargetCustomer && selectedAppointment.VisitID && (
            <div className="awf-infoItem">
              <span className="awf-infoLabel">Visit ID</span>
              <span className="awf-infoValue">
                {selectedAppointment.VisitID}
              </span>
            </div>
          )}

          {/* Address */}
          <div className="awf-infoItem">
            <span className="awf-infoLabel">Address</span>
            <span className="awf-infoValue">
              {serviceOrder?.address?.line1 || 'N/A'}
              {serviceOrder?.address?.line2 && (
                <>
                  <br />
                  {serviceOrder.address.line2}
                </>
              )}
              <br />
              {serviceOrder?.address?.city || 'N/A'},{' '}
              {serviceOrder?.address?.state || 'N/A'}{' '}
              {serviceOrder?.address?.zip || 'N/A'}
            </span>
          </div>

          {/* Contact */}
          <div className="awf-infoItem">
            <span className="awf-infoLabel">Contact</span>
            <span className="awf-infoValue">
              {serviceOrder?.contactName || 'N/A'}
              <br />
              Phone: {serviceOrder?.contactPhone || 'N/A'}
            </span>
          </div>
        </div>

        {/* Description Box */}
        <div className="awf-descriptionBox">
          <span className="awf-infoLabel">Description</span>
          <span className="awf-infoValue">
            {serviceOrder?.description || 'N/A'}
          </span>
        </div>

        {/* Status and Hold Buttons */}
        <div
          className="awf-statusSection"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <h3>Current Status: {currentStatus}</h3>
            <div className="awf-statusButtons">{renderNextStatusButton()}</div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {!isComplete && (
              <>
                <button
                  className="awf-holdButton btn btn-warning"
                  onClick={handleHoldButtonClick}
                >
                  {isOnHold ? 'Take off Hold' : 'Put on Hold'}
                </button>
                <div style={{ margin: '10px 0' }}></div>
              </>
            )}
            {isOnHold && selectedAppointment.OnHoldNotes && (
              <div className="awf-holdReason">
                <strong>Reason:</strong> {selectedAppointment.OnHoldNotes}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  /**
   * Render the next status button based on current status
   */
  const renderNextStatusButton = () => {
    const nextStatus = getNextStatus(currentStatus);
    const isOnHold = selectedAppointment?.OnHold;

    if (!nextStatus || isOnHold) return null;

    return (
      <button
        className="awf-statusButton"
        onClick={() => handleStatusChange(nextStatus)}
      >
        {nextStatus}
      </button>
    );
  };

  // New state for handling API call status
  const [addingItem, setAddingItem] = useState(false);
  const [addItemError, setAddItemError] = useState(null);

  /**
   * Extract the UsrAppointment suffix from the Appointment Number
   * @returns {string|null} - The suffix or null if not found
   */
  const getUsrAppointment = () => {
    if (!selectedAppointment || !selectedAppointment.AppointmentNumber) return null;

    console.log('AppointmentNumber:', selectedAppointment.AppointmentNumber);

    let usrAppointment = null;

    if (typeof selectedAppointment.AppointmentNumber === 'string') {
      const parts = selectedAppointment.AppointmentNumber.split('-');
      if (parts.length > 1) {
        usrAppointment = parseInt(parts[1], 10);
      } else if (!isNaN(parseInt(parts[0], 10))) {
        usrAppointment = parseInt(parts[0], 10);
      }
    } else if (typeof selectedAppointment.AppointmentNumber === 'number') {
      usrAppointment = selectedAppointment.AppointmentNumber;
    }

    console.log('Parsed UsrAppointment:', usrAppointment, typeof usrAppointment);

    return usrAppointment;
  };

  /**
   * Handle adding the selected part to Acumatica
   */
  const handleAddItemToAcumatica = async () => {
    if (
      !selectedAppointment ||
      !selectedAppointment.ServiceOrderID ||
      !selectedPart ||
      !quantity
    ) {
      setAddItemError('Missing required information.');
      return;
    }

    const usrAppointment = getUsrAppointment();
    if (!usrAppointment) {
      setAddItemError('Invalid Appointment Number format.');
      return;
    }

    const modifiedDescription = `${selectedPart.itemDescription} - ${user.email}`;

    const isServiceItem = selectedPart.ACUItemType === 'Service';
    const isStockItem = selectedPart.itemType === 'Stock';

    let lineType;
    let estimatedDuration;
    if (isServiceItem) {
      lineType = 'Service';
      const [hours, minutes] = quantity.split(':').map(Number);
      estimatedDuration = hours * 60 + minutes; // Convert to total minutes
    } else {
      lineType = isStockItem ? 'Inventory Item' : 'Non-Stock Item';
    }

    const payload = {
      serviceOrderNumber: selectedAppointment.ServiceOrderID,
      Details: [
        {
          InventoryID: String(selectedPart.itemID),
          LineType: lineType,
          Description: modifiedDescription,
          Qty: isServiceItem ? 0 : parseFloat(quantity), // Send 0 for service items
          UnitPrice: parseFloat(price) || 0, // Default to 0 if not set
          UsrAppointment: parseInt(usrAppointment, 10),
          UnitCost: parseFloat(cost) || 0, // Default to 0 if not set
          EstimatedDuration: isServiceItem ? estimatedDuration : undefined, // Send as total minutes
        },
      ],
    };

    console.log('Payload being sent:', JSON.stringify(payload, null, 2));

    setAddingItem(true);
    setAddItemError(null);

    try {
      const response = await fetch(ADD_ITEM_TO_ACUMATICA_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(
          `API responded with status ${response.status}: ${errorText}`
        );
      }

      const data = await response.json();
      console.log('Item added successfully:', data);

      fetchDetailItems(selectedAppointment.ServiceOrderID);
      handleCloseModal();
    } catch (error) {
      console.error('Error adding item to Acumatica:', error);
      setAddItemError('Failed to add item. Please try again.');
    } finally {
      setAddingItem(false);
    }
  };

  /**
   * Handle returning to the appointments list
   */
  const navigate = useNavigate(); // Initialize useNavigate

  const handleMenuClick = (section) => {
    setActiveSection(section);
  };

  const [collapsed, setCollapsed] = useState(true); // Initialize as collapsed

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  /**
   * Modify handleCloseModal to include API call on Finish
   */
  const handleCloseModal = () => {
    setShowPartsModal(false);
    setCurrentStep(1);
    setSelectedPart(null);
    setQuantity('');
    setPrice('');
    setCost('');
  };

  /**
   * Update the 'Finish' button to show loading state and add a 'Refresh' button
   */
  const renderModalFooter = () => {
    if (currentStep === 2) {
      return (
        <>
          <Button
            variant="secondary"
            onClick={handleBackStep}
            disabled={addingItem}
          >
            Back
          </Button>
          <Button
            variant="primary"
            onClick={handleAddItemToAcumatica}
            disabled={addingItem}
          >
            {addingItem ? 'Finishing...' : 'Finish'}
          </Button>
        </>
      );
    } else {
      return (
        <>
          <Button
            variant="secondary"
            onClick={refreshParts}
            disabled={partsLoading}
          >
            {partsLoading ? 'Refreshing...' : 'Refresh'}
          </Button>
          <Button
            variant="primary"
            onClick={handleNextStep}
            disabled={!selectedPart || addingItem}
          >
            Next
          </Button>
        </>
      );
    }
  };

  /**
   * Detail Items Cards with responsive stacking for mobile and full descriptions
   */
  const renderDetailItemsCards = () => {
    if (detailItemsLoading) {
      return (
        <div className="awf-loading">
          <Spinner animation="border" role="status" size="sm"></Spinner>
          <span> Loading detail items...</span>
        </div>
      );
    }

    if (detailItemsError) {
      return <div className="awf-error">{detailItemsError}</div>;
    }

    if (detailItems.length === 0) {
      return (
        <div className="awf-noDetailItems">
          No detail items found for this service order.
        </div>
      );
    }

    return (
      <Row xs={1} md={2} lg={3} className="g-4">
        {detailItems.map((item) => {
          // Create a unique key by concatenating ServiceOrderID and LineRef
          const uniqueKey = `${item.ServiceOrderID}-${item.LineRef}`;

          // Determine if this card corresponds to the currently selected appointment
          const isCurrentAppointment = item.AppointmentNumber === selectedAppointment.AppointmentNumber;

          return (
            <Col key={uniqueKey}>
              <Card className={`awf-detailCard ${isCurrentAppointment ? 'highlight' : ''}`}>
                <Card.Body>
                  <Card.Title>Line: {item.LineRef}</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">
                    ID: {item.InventoryID}
                  </Card.Subtitle>
                  <Card.Text>
                    <strong>Description:</strong> {item.Description || 'N/A'}
                  </Card.Text>
                  <Card.Text>
                    <strong>Qty:</strong> {item.Qty}
                  </Card.Text>
                  <Card.Text>
                    <strong>Appointment:</strong> {item.UsrAppointment}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          );
        })}
      </Row>
    );
  };

  const handleMenuItemClick = (section) => {
    setActiveSection(section);
  };

  // Add new state variables for image uploads
  const [selectedImages, setSelectedImages] = useState([]);
  const [showImageModal, setShowImageModal] = useState(false);
  const [uploadingImages, setUploadingImages] = useState(false);
  const [uploadError, setUploadError] = useState(null);

  // Handler for 'Upload Images' button click
  const handleUploadImagesClick = (event) => {
    event.preventDefault();
    // Open file picker
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*'; // Accept images only
    input.multiple = true; // Allow multiple files

    input.onchange = (e) => {
      const files = Array.from(e.target.files);
      handleImageSelection(files);
    };

    input.click();
  };

  // Handler for 'Take Photo' button click
  const handleTakePhotoClick = (event) => {
    event.preventDefault();
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.capture = 'environment'; // Use the rear camera if available

    input.onchange = (e) => {
      const files = Array.from(e.target.files);
      handleImageSelection(files);
    };

    input.click();
  };

  // Handle selected images
  const handleImageSelection = (files) => {
    // Process the selected files
    const newImages = files.map((file) => {
      return {
        file,
        preview: URL.createObjectURL(file),
      };
    });
    setSelectedImages((prevImages) => [...prevImages, ...newImages]);
    setShowImageModal(true);
  };

  // Handle closing the image modal
  const handleImageModalClose = () => {
    setShowImageModal(false);
  };

  // Handle deleting an image before upload
  const handleDeleteImage = (index) => {
    setSelectedImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  // Helper function to read file as Data URL
  const readFileAsDataURL = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  /**
   * Helper function to convert binary data to a Data URL
   * @param {ArrayBuffer} arrayBuffer - The binary data
   * @returns {Promise<string>} - The Data URL representing the image
   */
  const arrayBufferToDataURL = (arrayBuffer, mimeType) => {
    return new Promise((resolve, reject) => {
      const blob = new Blob([arrayBuffer], { type: mimeType });
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result); // This will be a Data URL
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  /**
   * Convert binary data to a thumbnail Data URL using Canvas
   * @param {string} dataUrl - The original image Data URL
   * @returns {Promise<string>} - The thumbnail Data URL
   */
  const createThumbnail = (dataUrl) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous'; // Handle CORS if necessary
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        // Set thumbnail size
        const MAX_WIDTH = 100;
        const MAX_HEIGHT = 100;

        let width = img.width;
        let height = img.height;

        // Calculate aspect ratio
        if (width > height) {
          if (width > MAX_WIDTH) {
            height = (height * MAX_WIDTH) / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width = (width * MAX_HEIGHT) / height;
            height = MAX_HEIGHT;
          }
        }

        canvas.width = width;
        canvas.height = height;

        ctx.drawImage(img, 0, 0, width, height);
        const thumbnailDataUrl = canvas.toDataURL();
        resolve(thumbnailDataUrl);
      };
      img.onerror = reject;
      img.src = dataUrl;
    });
  };

  /**
   * Render the Images and Files section without the "Upload Images" label
   */
  const renderImagesSection = () => {
    return (
      <div className="awf-imagesSection">
        <h3>Images and Files</h3>
        {/* Removed the "Upload Images" label */}
        <div className="awf-uploadButtons">
          <button
            className="awf-uploadButton"
            onClick={handleUploadImagesClick}
            aria-label="Upload Images"
          >
            <span className="awf-icon">📁</span>
          </button>
          <button
            className="awf-uploadButton"
            onClick={handleTakePhotoClick}
            aria-label="Take Photo"
          >
            <span className="awf-icon">📷</span>
          </button>
          {/* Capture Signature Button */}
          <button
            className="awf-uploadButton"
            onClick={handleOpenSignatureModal}
            aria-label="Capture Signature"
          >
            <span className="awf-icon">✍️</span>
          </button>
        </div>

        {/* Existing Image Modal */}
        {showImageModal && (
          <Modal
            show={showImageModal}
            onHide={handleImageModalClose}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Preview Selected Images and Signature</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* Selected Images Preview */}
              <div className="awf-selectedImagesContainer">
                {selectedImages.length === 0 ? (
                  <p>No images selected.</p>
                ) : (
                  selectedImages.map((image, index) => (
                    <div key={index} className="awf-imagePreview">
                      <img
                        src={image.preview}
                        alt={`Selected ${index}`}
                        className="awf-thumbnail"
                      />
                      <button
                        className="awf-deleteImageButton"
                        onClick={() => handleDeleteImage(index)}
                        aria-label={`Delete image ${index + 1}`}
                      >
                        Delete
                      </button>
                    </div>
                  ))
                )}
              </div>

              {/* Signature Preview */}
              {signature && (
                <div className="awf-signaturePreviewModal">
                  <h4>Signature Preview:</h4>
                  <img src={signature} alt="Signature Preview" className="awf-signatureImage" />
                  <button
                    className="awf-clearSignatureButtonModal"
                    onClick={clearSignature}
                    aria-label="Clear Signature"
                  >
                    Clear Signature
                  </button>
                </div>
              )}

              {uploadError && (
                <div className="awf-error">{uploadError}</div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={handleImageModalClose}
                disabled={uploadingImages}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={handleSubmitImages}
                disabled={
                  uploadingImages || (selectedImages.length === 0 && !signature)
                }
              >
                {uploadingImages ? 'Uploading...' : 'Submit'}
              </Button>
            </Modal.Footer>
          </Modal>
        )}

        {/* New Signature Capture Modal */}
        <Modal
          show={showSignatureModal}
          onHide={handleCloseSignatureModal}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Capture Signature</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="awf-signatureCapture">
              <SignatureCanvas
                penColor="black"
                canvasProps={{ width: 500, height: 200, className: 'signature-canvas' }}
                ref={signatureCanvasRef}
              />
              {signatureError && (
                <div className="awf-error">{signatureError}</div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={handleCloseSignatureModal}
              disabled={uploadingSignature}
            >
              Cancel
            </Button>
            <Button
              variant="warning"
              onClick={clearModalSignature}
              disabled={uploadingSignature}
            >
              Clear
            </Button>
            <Button
              variant="primary"
              onClick={handleSaveSignature}
              disabled={uploadingSignature}
            >
              {uploadingSignature ? 'Saving...' : 'Save'}
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Existing signature display */}
        {currentStatus === 'Complete' && (
          <div className="awf-formGroup">
            <label>Signature</label>
            <div className="awf-signatureBox">
              {signature ? (
                <img
                  src={signature}
                  alt="Signature"
                  className="awf-signatureImage"
                />
              ) : (
                <p>Click to sign</p>
              )}
            </div>
          </div>
        )}
      </div>
    );
  };

  /**
   * Handle submitting images and signature to the API endpoint
   */
  const handleSubmitImages = async () => {
    setUploadingImages(true);
    setUploadError(null);

    if (!selectedAppointment || !selectedAppointment.ServiceOrderID) {
      setUploadError('No appointment or service order selected.');
      setUploadingImages(false);
      return;
    }

    try {
      const filesData = await Promise.all(
        selectedImages.map(async ({ file }) => {
          const dataUrl = await readFileAsDataURL(file);
          const base64Data = dataUrl.split(',')[1]; // Remove data:image/jpeg;base64, etc.
          return {
            filename: file.name,
            fileData: base64Data,
          };
        })
      );

      // If a signature is present, add it as a separate file
      if (signature) {
        const signatureBase64 = signature.split(',')[1]; // Remove data:image/png;base64,
        filesData.push({
          filename: 'signature.png',
          fileData: signatureBase64,
        });
      }

      const payload = {
        serviceOrderNumber: selectedAppointment.ServiceOrderID,
        files: filesData,
      };

      const response = await fetch(ADD_IMAGES_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(
          `API responded with status ${response.status}: ${errorText}`
        );
      }

      // Handle success
      console.log('Images uploaded successfully.');
      setSelectedImages([]);
      setShowImageModal(false);

      // Optionally, show a success message to the user
      // You can implement a notification system or alert here
    } catch (error) {
      console.error('Error uploading images:', error);
      setUploadError('Failed to upload images. Please try again.');
    } finally {
      setUploadingImages(false);
    }
  };

  /**
   * Function to clear the signature
   */
  const clearSignature = () => {
    sigCanvas.current.clear();
    setSignature(null);
  };

  /**
   * Function to save the signature as a Data URL
   */
  const saveSignature = () => {
    if (!sigCanvas.current.isEmpty()) {
      const dataURL = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
      setSignature(dataURL);
    }
  };

  // New state variables for signature modal
  const [showSignatureModal, setShowSignatureModal] = useState(false);
  const signatureCanvasRef = useRef(null);
  const [currentSignature, setCurrentSignature] = useState(null);
  const [uploadingSignature, setUploadingSignature] = useState(false);
  const [signatureError, setSignatureError] = useState(null);

  /**
   * Handle opening the signature modal
   */
  const handleOpenSignatureModal = () => {
    setShowSignatureModal(true);
  };

  /**
   * Handle closing the signature modal
   */
  const handleCloseSignatureModal = () => {
    setShowSignatureModal(false);
    clearModalSignature();
  };

  /**
   * Clear the signature in the modal
   */
  const clearModalSignature = () => {
    if (signatureCanvasRef.current) {
      signatureCanvasRef.current.clear();
    }
    setCurrentSignature(null);
  };

  /**
   * Save the signature from the modal
   */
  const handleSaveSignature = async () => {
    if (
      signatureCanvasRef.current &&
      !signatureCanvasRef.current.isEmpty()
    ) {
      const signatureDataURL = signatureCanvasRef.current
        .getTrimmedCanvas()
        .toDataURL('image/png');
      setCurrentSignature(signatureDataURL);

      // Prepare the signature for upload
      const payload = {
        serviceOrderNumber: selectedAppointment.ServiceOrderID,
        files: [
          {
            filename: 'signature.png',
            fileData: signatureDataURL.split(',')[1], // Remove the data URL prefix
          },
        ],
      };

      setUploadingSignature(true);
      setSignatureError(null);

      try {
        const response = await fetch(ADD_IMAGES_ENDPOINT, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': API_KEY,
          },
          body: JSON.stringify(payload),
        });

        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(
            `API responded with status ${response.status}: ${errorText}`
          );
        }

        // Handle success
        console.log('Signature uploaded successfully.');
        setShowSignatureModal(false);
      } catch (error) {
        console.error('Error uploading signature:', error);
        setSignatureError('Failed to upload signature. Please try again.');
      } finally {
        setUploadingSignature(false);
      }
    } else {
      setSignatureError('Please provide a signature before saving.');
    }
  };

  // Add new state variables for Invoice Notes and Internal Notes
  const [invoiceNotes, setInvoiceNotes] = useState('');
  const [internalNotes, setInternalNotes] = useState('');

  // Add the new API endpoint for adding notes
  const ADD_NOTES_ENDPOINT =
    'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_add_notes_so';

  // State variables for managing the Add Note modal
  const [showAddNoteModal, setShowAddNoteModal] = useState(false);
  const [addNoteType, setAddNoteType] = useState('invoice'); // 'invoice' or 'internal'
  const [newNoteText, setNewNoteText] = useState('');
  const [addNoteLoading, setAddNoteLoading] = useState(false);
  const [addNoteError, setAddNoteError] = useState(null);

  // Function to open the Add Note modal
  const handleOpenAddNoteModal = (type) => {
    setAddNoteType(type);
    setShowAddNoteModal(true);
    setNewNoteText('');
    setAddNoteError(null);
  };

  // Function to close the Add Note modal
  const handleCloseAddNoteModal = () => {
    setShowAddNoteModal(false);
    setNewNoteText('');
    setAddNoteError(null);
  };

  // Function to save the new note
  const handleSaveNewNote = async () => {
    if (!newNoteText.trim()) {
      setAddNoteError('Note cannot be empty.');
      return;
    }

    setAddNoteLoading(true);
    setAddNoteError(null);

    try {
      // Extract serviceOrderNumber without appointment appended
      const serviceOrderNumber = selectedAppointment.ServiceOrderID.replace(/-\d+$/, '');

      // Prepare payload
      const payload = {
        serviceOrderNumber: serviceOrderNumber,
        newNote: DOMPurify.sanitize(newNoteText),
        username: `${user.email}:`,
        updateField: addNoteType === 'invoice' ? 'note' : 'UsrIntNotes',
      };

      const response = await fetch(ADD_NOTES_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`API responded with status ${response.status}: ${errorText}`);
      }

      const data = await response.json();
      console.log('Note added successfully:', data);

      // **Remove the immediate state update to ensure notes are fetched from the backend instead**
      // if (addNoteType === 'invoice') {
      //   setInvoiceNotes((prevNotes) => prevNotes + `<br/>${DOMPurify.sanitize(newNoteText)}`);
      // } else {
      //   setInternalNotes((prevNotes) => prevNotes + `<br/>${DOMPurify.sanitize(newNoteText)}`);
      // }

      // Refresh the notes to fetch the latest format from the backend
      await refreshNotes();

      // Close the modal
      handleCloseAddNoteModal();
    } catch (error) {
      console.error('Error adding note:', error);
      setAddNoteError('Failed to add note. Please try again.');
    } finally {
      setAddNoteLoading(false);
    }
  };

  /**
   * Function to parse and extract user-specific notes
   * @param {string} notes - The raw notes string
   * @returns {Object} - Object containing arrays of user notes and source system notes
   */
  const parseUserNotes = (notes, options = {}) => {
    const {
      entrySeparator = '-- end entry --',
      entryStartPattern = '-----',
      endEntryPattern = '-- end entry --',
    } = options;

    // Step 1: Strip all HTML tags and remove CSS styles
    let preprocessedNotes = DOMPurify.sanitize(notes, { USE_PROFILES: { html: false } });

    preprocessedNotes = preprocessedNotes.replace(/<style[\s\S]*?>[\s\S]*?<\/style>/gi, '');

    // Replace multiple spaces or newlines with a single newline
    preprocessedNotes = preprocessedNotes.replace(/\s*\n+\s*/g, '\n').trim();

    // Initialize arrays to hold different types of notes
    const userNotes = [];
    const systemNotes = [];

    // Regex to match user notes
    const userNoteRegex = new RegExp(
      `${entryStartPattern}\\s*(\\d{4}-\\d{2}-\\d{2} \\d{2}:\\d{2}:\\d{2} [A-Z]{3} \\([\\d-:\\sA-Z]*UTC\\))\\s*([\\w.\\-+]+@[\\w.\\-+]+\\.[\\w]+):\\s*([\\s\\S]*?)\\s*${endEntryPattern}`,
      'g'
    );

    let match;
    let lastIndex = 0;

    // Iterate over matches
    while ((match = userNoteRegex.exec(preprocessedNotes)) !== null) {
      const { index } = match;

      // Capture any text before the current match as a system note
      if (index > lastIndex) {
        const systemNoteText = preprocessedNotes.substring(lastIndex, index).trim();
        if (systemNoteText) {
          systemNotes.push({ content: systemNoteText });
        }
      }

      const [, timestamp, author, content] = match;
      userNotes.push({
        timestamp: timestamp.trim(),
        author: author.trim(),
        content: content.trim(),
      });

      lastIndex = userNoteRegex.lastIndex;
    }

    // Capture any remaining text after the last match as a system note
    if (lastIndex < preprocessedNotes.length) {
      const remainingText = preprocessedNotes.substring(lastIndex).trim();
      if (remainingText) {
        systemNotes.push({ content: remainingText });
      }
    }

    return { userNotes, systemNotes };
  };

  // State variables for managing the Delete Note modal
  const [showDeleteNoteModal, setShowDeleteNoteModal] = useState(false);
  const [selectedNote, setSelectedNote] = useState(null);
  const [deleteNoteLoading, setDeleteNoteLoading] = useState(false);
  const [deleteNoteError, setDeleteNoteError] = useState(null);

  // Function to open the Delete Note modal
  const handleOpenDeleteNoteModal = (type) => {
    setAddNoteType(type);
    setShowDeleteNoteModal(true);
    setSelectedNote(null);
    setDeleteNoteError(null);
  };

  // Function to close the Delete Note modal
  const handleCloseDeleteNoteModal = () => {
    setShowDeleteNoteModal(false);
    setSelectedNote(null);
    setDeleteNoteError(null);
  };

  // Function to delete the selected note
  const handleDeleteNote = async () => {
    if (selectedNote === null || selectedNote === undefined || selectedNote === '') {
      setDeleteNoteError('Please select a note to delete.');
      return;
    }

    // Determine which notes array to use based on the note type
    const notesArray = addNoteType === 'invoice' ? invoiceUserNotes.userNotes : internalUserNotes.userNotes;

    // Retrieve the selected note using the index
    const note = notesArray[selectedNote];

    if (!note) {
      setDeleteNoteError('Selected note does not exist.');
      return;
    }

    const { timestamp, author } = note;

    // Construct the payload with the colon appended to the username
    const payload = {
      serviceOrderNumber: selectedAppointment.ServiceOrderID.replace(/-\d+$/, ''),
      username: `${author}:`, // Append colon as required by the API
      timestamp: timestamp,
      updateField: addNoteType === 'invoice' ? 'note' : 'UsrIntNotes',
    };

    console.log('Deleting note with payload:', payload);

    try {
      setDeleteNoteLoading(true);
      const response = await fetch(
        'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_delete_note_so',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': API_KEY,
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`API responded with status ${response.status}: ${errorText}`);
      }

      console.log('Note deleted successfully.');

      // Refresh the notes to fetch the latest format from the backend
      await refreshNotes();

      // Close the modal
      handleCloseDeleteNoteModal();
    } catch (error) {
      console.error('Error deleting note:', error);
      setDeleteNoteError('Failed to delete note. Please try again.');
    } finally {
      setDeleteNoteLoading(false);
    }
  };

  // Function to format user-added notes
  const formatUserNotes = (notes) => {
    // Regular expression to match user-added notes
    const userNotePattern = /-{5}\s*(\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2} [A-Z]{3} \(\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2} UTC\))\s*(.*?):\s*(.*?)\s*-- end entry --/g;

    // Replace user-added notes with formatted HTML
    return notes.replace(userNotePattern, (match, timestamp, username, content) => {
      return `
        <hr/>
        <strong>${timestamp}</strong><br/>
        <em>${username}:</em><br/>
        ${content}<br/>
        -- end entry --<br/><br/>
      `;
    });
  };

  // Define parsed notes at the component level
  const invoiceUserNotes = parseUserNotes(invoiceNotes, {
    entrySeparator: '- - end entry - -',
    entryStartPattern: '- - - - -',
    endEntryPattern: '- - end entry - -',
  });
  console.log('Parsed Invoice Notes:', invoiceUserNotes);
  const internalUserNotes = parseUserNotes(internalNotes);

  /**
   * Function to format notes for display
   * @param {Array} userNotes - Array of user-added note objects
   * @param {Array} sourceSystemNotes - Array of source system note objects
   * @returns {string} - Formatted HTML string for display
   */
  const formatNotesForDisplay = (userNotes, systemNotes) => {
    // Format user notes
    const formattedUserNotes = userNotes.map((note) => {
      const { timestamp, author, content } = note;
      return `
        <div style="margin-bottom: 20px; border-bottom: 1px solid #ccc; padding-bottom: 10px;">
          <span>${timestamp || 'Unknown Date'}</span><br />
          <em>${author || 'Unknown User'}</em><br />
          <strong>${content || 'No content available'}</strong>
          <div style="text-align: right;">-- end entry --</div>
        </div>
      `;
    }).join('');

    // Format system notes
    const formattedSystemNotes = systemNotes.map((note) => {
      return `
        <div style="margin-bottom: 20px; border-bottom: 1px solid #ccc; padding-bottom: 10px;">
          <strong>${note.content || 'No content available'}</strong>
        </div>
      `;
    }).join('');

    return formattedSystemNotes + formattedUserNotes;
  };

  /**
   * Render the notes section with Add and Delete buttons and modal
   */
  const renderNotesSection = () => {
    // Format the internal notes for display
    const formattedInternalNotes = formatNotesForDisplay(
      internalUserNotes.userNotes,
      internalUserNotes.systemNotes // Include system notes
    );

    // Format the invoice notes for display
    const formattedInvoiceNotes = formatNotesForDisplay(invoiceUserNotes.userNotes, invoiceUserNotes.systemNotes);
    console.log('Formatted Invoice Notes:', formattedInvoiceNotes);

    return (
      <div className="awf-notesSection">
        <h3>Notes</h3>

        {/* Invoice Notes Section */}
        <div className="awf-noteHeader" style={{ marginBottom: '10px' }}>
          <label htmlFor="invoiceNotes">Invoice Notes</label>
        </div>
        <div
          className="awf-formGroup awf-notesFormGroup"
          style={{ marginBottom: '20px', position: 'relative' }}
        >
          {notesLoading ? (
            <div className="awf-loading">
              <Spinner animation="border" role="status" size="sm" />
              <span> Loading notes...</span>
            </div>
          ) : notesError ? (
            <div className="awf-error">{notesError}</div>
          ) : (
            <div
              id="invoiceNotes"
              className="awf-invoiceNotesBox awf-scrollableBox"
              style={{
                whiteSpace: 'pre-wrap',
                padding: '10px',
                border: '1px solid #ccc',
                maxHeight: '200px',
                overflowY: 'auto',
              }}
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(formattedInvoiceNotes) }}
            ></div>
          )}
          <div className="awf-noteButtons">
            <Button
              variant="primary"
              size="sm"
              onClick={() => handleOpenAddNoteModal('invoice')}
              className="awf-addNoteButton"
            >
              Add
            </Button>
            <Button
              variant="danger"
              size="sm"
              onClick={() => handleOpenDeleteNoteModal('invoice')}
              className="awf-deleteNoteButton"
            >
              Delete
            </Button>
          </div>
        </div>

        {/* Internal Notes Section */}
        <div className="awf-noteHeader" style={{ marginBottom: '10px' }}>
          <label htmlFor="internalNotes">Internal Notes</label>
        </div>
        <div
          className="awf-formGroup awf-notesFormGroup"
          style={{ marginBottom: '20px', position: 'relative' }}
        >
          {notesLoading ? (
            <div className="awf-loading">
              <Spinner animation="border" role="status" size="sm" />
              <span> Loading notes...</span>
            </div>
          ) : notesError ? (
            <div className="awf-error">{notesError}</div>
          ) : (
            <div
              id="internalNotes"
              className="awf-internalNotesBox awf-scrollableBox"
              style={{
                whiteSpace: 'pre-wrap',
                padding: '10px',
                border: '1px solid #ccc',
                maxHeight: '200px',
                overflowY: 'auto',
              }}
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(formattedInternalNotes) }}
            ></div>
          )}
          <div className="awf-noteButtons">
            <Button
              variant="primary"
              size="sm"
              onClick={() => handleOpenAddNoteModal('internal')}
              className="awf-addNoteButton"
            >
              Add
            </Button>
            <Button
              variant="danger"
              size="sm"
              onClick={() => handleOpenDeleteNoteModal('internal')}
              className="awf-deleteNoteButton"
            >
              Delete
            </Button>
          </div>
        </div>

        {/* Permanent Notes Header */}
        <div className="awf-noteHeader" style={{ marginBottom: '10px' }}>
          <label htmlFor="customerNotes">Permanent Notes</label>
        </div>
        <div className="awf-formGroup awf-notesFormGroup">
          {renderCustomerNotes()}
        </div>

        {/* Existing Error Handling (if any) */}
        {error && <div className="awf-error">{error}</div>}

        {/* Add Notes Modal */}
        {showAddNoteModal && (
          <Modal
            show={showAddNoteModal}
            onHide={handleCloseAddNoteModal}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Add {addNoteType === 'invoice' ? 'Invoice' : 'Internal'} Note</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="awf-formGroup">
                <label htmlFor="newNote">New Note</label>
                <textarea
                  id="newNote"
                  value={newNoteText}
                  onChange={(e) => setNewNoteText(e.target.value)}
                  className="awf-textArea"
                  rows="4"
                  placeholder="Enter your note here..."
                />
              </div>
              {addNoteError && (
                <div className="awf-error">{addNoteError}</div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseAddNoteModal}>
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={handleSaveNewNote}
                disabled={addNoteLoading}
              >
                {addNoteLoading ? 'Saving...' : 'Save'}
              </Button>
            </Modal.Footer>
          </Modal>
        )}

        {/* Delete Notes Modal */}
        {showDeleteNoteModal && (
          <Modal
            show={showDeleteNoteModal}
            onHide={handleCloseDeleteNoteModal}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Delete {addNoteType === 'invoice' ? 'Invoice' : 'Internal'} Note
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="awf-formGroup">
                <label htmlFor="selectedNote">Select Note to Delete</label>
                <Form.Control
                  as="select"
                  id="selectedNote"
                  value={selectedNote !== null ? selectedNote : ''}
                  onChange={(e) => setSelectedNote(e.target.value)}
                >
                  <option value="" disabled>
                    Select a note
                  </option>
                  {/* Display note content in the dropdown */}
                  {(addNoteType === 'invoice' ? invoiceUserNotes.userNotes : internalUserNotes.userNotes).map(
                    (note, index) => (
                      <option key={index} value={index}>
                        {note.content.substring(0, 30)}... {/* Display first 30 characters of the note content */}
                      </option>
                    )
                  )}
                </Form.Control>
              </div>
              {deleteNoteError && (
                <div className="awf-error">{deleteNoteError}</div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseDeleteNoteModal}>
                Cancel
              </Button>
              <Button
                variant="danger"
                onClick={handleDeleteNote}
                disabled={deleteNoteLoading}
              >
                {deleteNoteLoading ? 'Deleting...' : 'Delete'}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>
    );
  };

  /**
   * Refresh the Invoice and Internal Notes by refetching the service orders
   */
  const refreshNotes = async () => {
    if (!selectedAppointment || !selectedAppointment.ServiceOrderID) {
      console.warn('No appointment or service order selected.');
      return;
    }

    try {
      // Fetch the service order details
      const response = await fetch(GET_SERVICE_ORDERS_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY,
        },
        body: JSON.stringify({
          serviceOrderIDs: [
            selectedAppointment.ServiceOrderID.replace('FS', ''),
          ],
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error(`API Error Response: ${errorText}`);
        throw new Error(`API responded with status ${response.status}`);
      }

      const data = await response.json();
      console.log('Fetched Service Order for Notes:', data);

      // Parse the response body
      let serviceOrdersArray = [];

      if (data.body) {
        serviceOrdersArray = JSON.parse(data.body);
      } else if (data.data) {
        // In case Lambda returns data directly
        serviceOrdersArray = data.data;
      } else {
        // Handle unexpected response format
        console.warn('Unexpected response format from service orders API');
        return;
      }

      if (Array.isArray(serviceOrdersArray) && serviceOrdersArray.length > 0) {
        const serviceOrder = serviceOrdersArray[0];
        const { additionalFields } = serviceOrder;

        // Check if additionalFields exists
        if (additionalFields) {
          // Extract 'note' from additionalFields
          const note = additionalFields.note;

          // Log the raw note data
          console.log('Raw Invoice Note:', note);

          // Unescape the note string to handle escaped characters
          const unescapedNote = note ? unescape(note) : 'No content available.';

          // Log the unescaped note data
          console.log('Unescaped Invoice Note:', unescapedNote);

          // Set the Invoice Notes
          setInvoiceNotes(unescapedNote);

          // Internal Notes
          if (additionalFields.UsrIntNotes) {
            setInternalNotes(additionalFields.UsrIntNotes);
          } else {
            setInternalNotes('No content available.');
          }
        } else {
          // Handle the case where additionalFields is missing
          setInvoiceNotes('No content available.');
          setInternalNotes('No content available.');
        }
      } else {
        console.warn('No service order data found for refreshing notes.');
        setInvoiceNotes('No content available.');
        setInternalNotes('No content available.');
      }
    } catch (error) {
      console.error('Error refreshing notes:', error);
      setInvoiceNotes('Error loading invoice notes.');
      setInternalNotes('Error loading internal notes.');
    }
  };

  // **Add new state variables for Hold functionality**
  const [showHoldModal, setShowHoldModal] = useState(false); // State to control Hold modal visibility
  const [holdReason, setHoldReason] = useState(''); // State to store the reason for hold
  const [holdLoading, setHoldLoading] = useState(false); // New state for hold operation loading

  // **Add handler functions for Hold functionality**
  const toggleHoldModal = () => {
    setShowHoldModal(!showHoldModal);
  };

  const handleHoldButtonClick = async () => {
    if (!selectedAppointment) return;

    const isOnHold = selectedAppointment.OnHold === 1; // Ensure this is a boolean
    if (isOnHold) {
      // Directly remove hold without showing modal
      await handleSaveHold();
    } else {
      // Show modal to add hold reason
      toggleHoldModal();
    }
  };

  const handleSaveHold = async () => {
    if (!selectedAppointment) return;

    setHoldLoading(true); // Set loading state to true

    const isOnHold = selectedAppointment.OnHold;
    const payload = {
      ServiceOrderID: selectedAppointment.ServiceOrderID,
      AppointmentNumber: selectedAppointment.AppointmentNumber,
      OnHold: isOnHold ? 0 : 1,
      OnHoldNotes: isOnHold ? '' : holdReason,
    };

    try {
      const response = await fetch('https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_appointment_onhold', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`API responded with status ${response.status}: ${errorText}`);
      }

      console.log('Hold status updated successfully.');

      // Update the selectedAppointment's OnHold status and notes in state
      setSelectedAppointment((prev) => ({
        ...prev,
        OnHold: isOnHold ? 0 : 1,
        OnHoldNotes: isOnHold ? '' : holdReason,
      }));

      // Update the appointments list to reflect the new hold status
      setAppointments((prevAppointments) =>
        prevAppointments.map((appt) =>
          appt.AppointmentNumber === selectedAppointment.AppointmentNumber
            ? { ...appt, OnHold: isOnHold ? 0 : 1 }
            : appt
        )
      );

      if (!isOnHold) {
        toggleHoldModal();
      }
    } catch (error) {
      console.error('Error updating hold status:', error);
      setError('Failed to update hold status. Please try again later.');
    } finally {
      setHoldLoading(false); // Reset loading state
      setHoldReason(''); // Clear hold reason after processing
    }
  };

  const handleCancelHold = () => {
    // Stubbed function: Implement any necessary cleanup here
    setHoldReason('');
    toggleHoldModal();
  };

  /**
   * Render the Hold Modal
   */
  const renderHoldModal = () => {
    if (!selectedAppointment) return null; // Ensure selectedAppointment is not null

    const isOnHold = selectedAppointment.OnHold === 1; // Ensure this is a boolean

    return (
      <Modal show={showHoldModal} onHide={toggleHoldModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>{isOnHold ? 'Take off Hold' : 'Put on Hold'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="awf-formGroup">
            <label htmlFor="holdReason">Reason for {isOnHold ? 'Taking off Hold' : 'Putting on Hold'}</label>
            <textarea
              id="holdReason"
              value={holdReason}
              onChange={(e) => setHoldReason(e.target.value)}
              className="awf-textArea form-control"
              rows="4"
              placeholder="Enter the reason here..."
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelHold}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSaveHold} disabled={holdLoading}>
            {holdLoading ? (
              <>
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />{' '}
                Saving...
              </>
            ) : (
              'Save'
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  // **New Endpoints for Create PO Functionality**
  const GET_VENDORS_ENDPOINT =
    'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_get_vendors';
  const CREATE_PO_ENDPOINT =
    'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_create_po';

  // **New State Variables for Create PO Modal**
  const [showCreatePOModal, setShowCreatePOModal] = useState(false);
  const [vendors, setVendors] = useState([]); // Initialize as empty array
  const [selectedVendor, setSelectedVendor] = useState('');
  const [poNotes, setPONotes] = useState('');
  const [vendorsLoading, setVendorsLoading] = useState(false);
  const [vendorsError, setVendorsError] = useState(null);
  const [creatingPO, setCreatingPO] = useState(false);
  const [createPOError, setCreatePOError] = useState(null);

  const [vendorSearchTerm, setVendorSearchTerm] = useState(''); // New state for vendor search term

  // Filter vendors based on the search term
  const filteredVendors = vendors.filter((vendor) => {
    const vendorID = vendor.VendorID?.value || ''; // Access VendorID.value
    const vendorName = vendor.VendorName?.value || ''; // Access VendorName.value

    return (
      vendorID.toLowerCase().includes(vendorSearchTerm.toLowerCase()) ||
      vendorName.toLowerCase().includes(vendorSearchTerm.toLowerCase())
    );
  });

  // State for cached vendors
  const [cachedVendors, setCachedVendors] = useState([]);

  /**
   * Function to fetch vendors from the Lambda function
   */
  const fetchVendors = async (forceRefresh = false) => {
    if (!forceRefresh && cachedVendors.length > 0) {
      // Use cached vendors if available
      setVendors(cachedVendors);
      return;
    }

    setVendorsLoading(true);
    setVendorsError(null);
    console.log('Starting fetchVendors...');

    try {
      const response = await fetch(GET_VENDORS_ENDPOINT, {
        method: 'GET', // GET request
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY,
        },
        // No body required for GET request
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error(`API Error Response: ${errorText}`);
        throw new Error(`API responded with status ${response.status}`);
      }

      const data = await response.json();
      console.log('Fetched Vendors:', data);

      // Handle the actual response format
      if (data.vendors && Array.isArray(data.vendors)) {
        setVendors(data.vendors);
        setCachedVendors(data.vendors); // Cache the vendors
      } else {
        throw new Error('Vendors data is not in expected format.');
      }
    } catch (err) {
      console.error('Error fetching vendors:', err);
      setVendorsError('Failed to load vendors. Please try again later.');
    } finally {
      setVendorsLoading(false);
      console.log('fetchVendors completed.');
    }
  };

  /**
   * Function to handle opening the Create PO modal
   */
  const handleOpenCreatePOModal = () => {
    setShowCreatePOModal(true);
    fetchVendors(); // Fetch vendors without forcing refresh
  };

  /**
   * Function to handle closing the Create PO modal
   */
  const handleCloseCreatePOModal = () => {
    setShowCreatePOModal(false);
    setSelectedVendor('');
    setPONotes('');
    setCreatePOError(null);
  };

  /**
   * Function to handle creating the PO
   */
  const handleCreatePO = async () => {
    if (!selectedVendor) {
      setCreatePOError('Please select a vendor.');
      return;
    }

    if (!selectedAppointment) {
      setCreatePOError('No appointment selected.');
      return;
    }

    // Find the selected vendor object to get the VendorName
    const selectedVendorObject = vendors.find(
      (vendor) => vendor.VendorID.value === selectedVendor
    );

    if (!selectedVendorObject) {
      setCreatePOError('Selected vendor not found.');
      return;
    }

    // Concatenate VendorID and VendorName
    const vendorDisplayName = `${selectedVendorObject.VendorID.value} - ${selectedVendorObject.VendorName.value}`;

    const payload = {
      serviceOrderNumber: selectedAppointment.ServiceOrderID,
      Appointment: selectedAppointment.AppointmentNumber,
      Technician: user.email, // Ensure 'user.email' contains the technician's username
      Vendor: vendorDisplayName, // Use the concatenated VendorID and VendorName
      Notes: poNotes,
    };

    setCreatingPO(true);
    setCreatePOError(null);

    try {
      const response = await fetch(CREATE_PO_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(
          `API responded with status ${response.status}: ${errorText}`
        );
      }

      const data = await response.json();
      console.log('PO created successfully:', data);

      // **Add the following line to refresh the parts list**
      await refreshParts();

      // Close the modal
      handleCloseCreatePOModal();
    } catch (error) {
      console.error('Error creating PO:', error);
      setCreatePOError('Failed to create PO. Please try again.');
    } finally {
      setCreatingPO(false);
    }
  };

  const renderCreatePOModal = () => (
    <Modal
      show={showCreatePOModal}
      onHide={handleCloseCreatePOModal}
      centered
      size="lg" // Optional: Adjust modal size if needed
    >
      <Modal.Header closeButton>
        <Modal.Title>Create Purchase Order</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {vendorsLoading ? (
          <div className="awf-loading">
            <Spinner animation="border" role="status" size="sm"></Spinner>
            <span> Loading vendors...</span>
          </div>
        ) : vendorsError ? (
          <div className="awf-error">{vendorsError}</div>
        ) : (
          <div>
            {/* Vendor Search and Dynamic List */}
            <div className="awf-formGroup">
              <label>Search and Select Vendor</label>
              <input
                type="text"
                value={vendorSearchTerm}
                onChange={(e) => setVendorSearchTerm(e.target.value)}
                className="awf-textInput form-control"
                placeholder="Search by Vendor ID or Name..."
              />
              {/* Scrollable Vendor List */}
              <div
                className="awf-vendorList"
                style={{
                  maxHeight: '200px', // Adjust height as needed
                  overflowY: 'auto',
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  marginTop: '5px',
                  padding: '5px',
                }}
              >
                {filteredVendors.length > 0 ? (
                  filteredVendors.map((vendor) => (
                    <div
                      key={vendor.VendorID.value}
                      className={`awf-vendorItem ${
                        selectedVendor === vendor.VendorID.value ? 'selected' : ''
                      }`}
                      onClick={() => setSelectedVendor(vendor.VendorID.value)}
                      style={{
                        padding: '8px',
                        cursor: 'pointer',
                        backgroundColor:
                          selectedVendor === vendor.VendorID.value ? '#e6f7ff' : 'transparent',
                      }}
                    >
                      {`${vendor.VendorID.value} - ${vendor.VendorName.value}`}
                    </div>
                  ))
                ) : (
                  <div className="awf-noVendors" style={{ padding: '8px', color: '#888' }}>
                    No vendors found.
                  </div>
                )}
              </div>
            </div>

            {/* Notes Input */}
            <div className="awf-formGroup" style={{ marginTop: '20px' }}>
              <label>Notes</label>
              <textarea
                className="awf-textArea form-control"
                value={poNotes}
                onChange={(e) => setPONotes(e.target.value)}
                rows="4"
                placeholder="Enter any additional notes here..."
              />
            </div>
          </div>
        )}
        {createPOError && <div className="awf-error">{createPOError}</div>}
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-between w-100">
          <Button
            variant="outline-secondary"
            onClick={() => fetchVendors(true)}
            disabled={vendorsLoading}
          >
            {vendorsLoading ? 'Refreshing...' : 'Refresh'}
          </Button>
          <div>
            <Button variant="secondary" onClick={handleCloseCreatePOModal} className="me-2">
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={handleCreatePO}
              disabled={creatingPO || !selectedVendor}
            >
              {creatingPO ? 'Creating...' : 'Create PO'}
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );

  /**
   * Render the Parts Section with styled cards instead of a table
   */
  const renderPartsSection = () => {
    return (
      <div className="awf-partsSection">
        <h3>Parts</h3>

        {/* Action Buttons */}
        <div className="d-flex mb-3">
          <Button
            variant="primary"
            className="me-2"
            onClick={() => setShowPartsModal(true)}
          >
            Add Parts
          </Button>
          <Button
            variant="success"
            onClick={handleOpenCreatePOModal}
          >
            Create PO
          </Button>
        </div>

        {/* Detail Items Cards */}
        <div className="awf-detailItemsSection">
          {renderDetailItemsCards()}
        </div>

        {/* Create PO Modal */}
        {showCreatePOModal && renderCreatePOModal()}
      </div>
    );
  };

  /**
   * Function to refresh parts list and update cache
   */
  const refreshParts = async () => {
    setPartsLoading(true);
    setPartsError(null);
    console.log('Refreshing parts...');

    try {
      const response = await fetch(GET_ALL_ITEMS_ENDPOINT, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY,
        },
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error(`API Error Response: ${errorText}`);
        throw new Error(`API responded with status ${response.status}`);
      }

      const data = await response.json();
      console.log('Refreshed Parts:', data);

      if (Array.isArray(data)) {
        setParts(data);
        setCachedVendors(data); // Update the cache
      } else if (data.body) {
        const parsedData = JSON.parse(data.body);
        setParts(parsedData);
        setCachedVendors(parsedData); // Update the cache
      } else {
        throw new Error('Unexpected API response format');
      }
    } catch (err) {
      console.error('Error refreshing parts:', err);
      setPartsError('Failed to refresh parts. Please try again later.');
    } finally {
      setPartsLoading(false);
      console.log('refreshParts completed.');
    }
  };

  /**
   * Render the form for entering part details
   */
  const renderPartDetailsForm = () => {
    if (!selectedPart) return null;

    const isServiceItem = selectedPart.ACUItemType === 'Service';
    const isStockItem = selectedPart.itemType === 'Stock';

    const handleTimeChange = (hours, minutes) => {
        setQuantity(`${hours}:${minutes}`);
    };

    // Determine if the selected part is special
    const isSpecialPart = selectedPart && SPECIAL_PART_NUMBERS.includes(selectedPart.itemID);

    // Function to check if a value is meaningful
    const isMeaningfulValue = (value) => {
      return value && value !== '0' && value !== 'N/A' && value !== 'null';
    };

    return (
      <div className="awf-partDetailsForm">
        <div className="awf-formGroup">
          <label>{isServiceItem ? 'Estimated Duration' : 'Quantity'}</label>
          {isServiceItem ? (
            <div className="time-picker">
              <select
                onChange={(e) => handleTimeChange(e.target.value, quantity.split(':')[1] || '00')}
                value={quantity.split(':')[0] || '00'}
              >
                {Array.from({ length: 24 }, (_, i) => (
                  <option key={i} value={String(i).padStart(2, '0')}>
                    {String(i).padStart(2, '0')}
                  </option>
                ))}
              </select>
              :
              <select
                onChange={(e) => handleTimeChange(quantity.split(':')[0] || '00', e.target.value)}
                value={quantity.split(':')[1] || '00'}
              >
                {['00', '15', '30', '45'].map((minute) => (
                  <option key={minute} value={minute}>
                    {minute}
                  </option>
                ))}
              </select>
            </div>
          ) : (
            <input
              type="text"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value.replace(/[^0-9]/g, ''))}
              placeholder="Enter quantity"
            />
          )}
        </div>
        <div className="awf-formGroup">
          {isSpecialPart || isMeaningfulValue(price) ? (
            <div className="awf-formGroup">
              <label>Cost</label>
              <input
                type="text"
                value={`$${price}`}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9.]/g, '');
                  setPrice(value);
                }}
                placeholder="$0.00"
                inputMode="decimal"
                disabled={!isSpecialPart} // Disable by default, enable for special parts
                style={{ backgroundColor: !isSpecialPart ? '#f0f0f0' : 'white' }} // Gray out if disabled
              />
            </div>
          ) : null}

          {isSpecialPart || isMeaningfulValue(cost) ? (
            <div className="awf-formGroup">
              <label>Price</label>
              <input
                type="text"
                value={isSpecialPart ? `$${cost}` : `$${selectedPart?.price || '0.00'}`} // Show cost for special parts
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9.]/g, '');
                  setCost(value);
                }}
                placeholder="$0.00"
                inputMode="decimal"
                disabled={!isSpecialPart} // Disable by default, enable for special parts
                style={{ backgroundColor: !isSpecialPart ? '#f0f0f0' : 'white' }} // Gray out if disabled
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  /**
   * Render the Add Parts modal
   */
  const renderAddPartsModal = () => (
    <Modal show={showPartsModal} onHide={handleCloseModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          {currentStep === 1 ? 'Select Part' : 'Enter Quantity, Cost, and Price'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {currentStep === 1 ? (
          <div>
            {/* Search Input */}
            <div className="awf-formGroup">
              <label>Search Parts</label>
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="awf-textInput"
                placeholder="Search by part number or description..."
              />
            </div>

            {/* Parts List */}
            <div
              className="awf-partsList"
              style={{
                maxHeight: '400px',
                overflowY: 'auto',
              }}
            >
              {filteredParts.map((part, index) => (
                <div
                  key={index}
                  className={`awf-partItem ${
                    selectedPart === part ? 'selected' : ''
                  }`}
                  onClick={() => setSelectedPart(part)}
                  style={{
                    cursor: 'pointer',
                    padding: '10px',
                    border:
                      selectedPart === part
                        ? '2px solid blue'
                        : '1px solid gray',
                  }}
                >
                  <strong>{part.itemID}</strong>: {part.itemDescription}
                </div>
              ))}
            </div>
          </div>
        ) : (
          renderPartDetailsForm()
        )}
      </Modal.Body>
      <Modal.Footer>
        {renderModalFooter()}
      </Modal.Footer>
    </Modal>
  );

  /**
   * Render the Appointments Listing
   */
  const renderAppointmentsListing = () => {
    if (appointmentsLoading) {
      return (
        <div className="awf-loading">
          <Spinner animation="border" role="status" size="sm"></Spinner>
          <span> Loading appointments...</span>
        </div>
      );
    }

    if (error) {
      return <div className="awf-error">{error}</div>;
    }

    if (appointments.length === 0) {
      return (
        <div className="awf-noAppointments">
          No appointments found.
        </div>
      );
    }

    return (
      <Row xs={1} md={2} lg={3} className="g-4">
        {appointments.map((appointment) => {
          const serviceOrder = serviceOrders.find(
            (so) => so.serviceOrderNumber === appointment.ServiceOrderID
          );

          const concatenatedId = `${appointment.ServiceOrderID}-${appointment.AppointmentNumber}`;
          const serviceOrderDate = formatDate(serviceOrder?.date);
          const isOnHold =
            appointment.OnHold === 1 || appointment.OnHold === true; // Adjust based on your data

          return (
            <Col key={concatenatedId}>
              <Card
                className="awf-appointmentCard"
                onClick={() => handleSelectAppointment(appointment)}
              >
                <Card.Body>
                  <Card.Title>{concatenatedId}</Card.Title>
                  <Card.Text>
                    <strong>Customer:</strong>{' '}
                    {serviceOrder?.customerName || 'N/A'}
                  </Card.Text>
                  <Card.Text>
                    <strong>Service Order Date:</strong>{' '}
                    {serviceOrderDate || 'N/A'}
                  </Card.Text>
                  <Card.Text>
                    <strong>Status:</strong> {appointment.Status || 'N/A'}
                  </Card.Text>
                  {isOnHold && (
                    <Card.Text>
                      <strong style={{ color: 'red' }}>On Hold</strong>
                    </Card.Text>
                  )}
                </Card.Body>
              </Card>
            </Col>
          );
        })}
      </Row>
    );
  };

  // State variables for notes loading and errors
  const [notesLoading, setNotesLoading] = useState(false);
  const [notesError, setNotesError] = useState(null);

  /**
   * Fetch service order notes using the specific endpoint
   */
  const fetchServiceOrderNotes = async (serviceOrderNumber) => {
    if (!serviceOrderNumber) {
      console.warn('No service order number provided. Cannot fetch notes.');
      return;
    }
    
    setNotesLoading(true);
    setNotesError(null);

    try {
      const response = await fetch(GET_SERVICE_ORDERS_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY,
        },
        body: JSON.stringify({ serviceOrderIDs: [serviceOrderNumber] }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error(`API Error Response: ${errorText}`);
        throw new Error(`API responded with status ${response.status}`);
      }

      const data = await response.json();
      console.log('Fetched Service Order Details:', data);

      // Parse the response body
      let serviceOrdersArray = [];

      if (data.body) {
        serviceOrdersArray = JSON.parse(data.body);
      } else if (data.data) {
        serviceOrdersArray = data.data;
      } else {
        throw new Error('Unexpected API response format');
      }

      if (Array.isArray(serviceOrdersArray) && serviceOrdersArray.length > 0) {
        const serviceOrder = serviceOrdersArray[0];
        const additionalFields = serviceOrder.additionalFields || {};

        // Extract the invoice notes from serviceOrder.note
        let invoiceNote = '';

        if (serviceOrder.note) {
          if (typeof serviceOrder.note.value === 'string') {
            invoiceNote = serviceOrder.note.value;
          } else if (typeof serviceOrder.note === 'string') {
            invoiceNote = serviceOrder.note;
          }
        }

        // Extract the internal notes
        const internalNote = serviceOrder.additionalFields?.UsrIntNotes || '';

        // Set the notes in state
        setInvoiceNotes(invoiceNote);
        setInternalNotes(internalNote);
      } else {
        setInvoiceNotes('No invoice notes available.');
        setInternalNotes('No internal notes available.');
      }
    } catch (err) {
      console.error('Error fetching service order notes:', err);
      setNotesError('Failed to load notes. Please try again later.');
      setInvoiceNotes('Error loading invoice notes.');
      setInternalNotes('Error loading internal notes.');
    } finally {
      setNotesLoading(false);
    }
  };

  const renderCustomerNotes = () => {
    return (
      <div className="awf-customerNotesSection">    
        {customerNotesLoading ? (
          <div className="awf-loading">
            <Spinner animation="border" role="status" size="sm"></Spinner>
            <span> Loading customer notes...</span>
          </div>
        ) : customerNotesError ? (
          <div className="awf-error">{customerNotesError}</div>
        ) : (
          <div
            className="awf-customerNotesBox awf-scrollableBox"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(customerNotes),
            }}
          />
        )}
      </div>
    );
  };

  // New state variables for geolocation
  const [location, setLocation] = useState({
    latitude: null,
    longitude: null,
  });
  const [locationError, setLocationError] = useState(null);

  /**
   * Retrieve the current geolocation of the user.
   * @returns {Promise<{latitude: number, longitude: number}>}
   */
  const getCurrentGeolocation = () => {
    return new Promise((resolve, reject) => {
      if (!navigator.geolocation) {
        reject(new Error('Geolocation is not supported by your browser.'));
      } else {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            });
          },
          (error) => {
            reject(error);
          }
        );
      }
    });
  };

  /**
   * Update the target visit status by calling the specified API endpoint.
   * @param {string} status - The visit status to update ("En route", "Check-in", "Check-out").
   */
  const updateTargetVisit = async (status) => {
    // Ensure an appointment is selected
    if (!selectedAppointment) {
      console.error('No appointment selected.');
      return;
    }

    // Extract service order details
    const serviceOrder = serviceOrders.find(
      (so) => so.serviceOrderNumber === selectedAppointment.ServiceOrderID
    );

    if (!serviceOrder) {
      console.error('No corresponding service order found.');
      return;
    }

    // Check if customer name includes 'target' (case-insensitive) and customer order number is not blank
    const customerName = serviceOrder.customerName || '';
    const customerOrderNumber = serviceOrder.customerOrder || '';

    if (
      !customerName.toLowerCase().includes('target') ||
      customerOrderNumber.trim() === ''
    ) {
      console.log('Conditions not met. Skipping updateTargetVisit.');
      return;
    }

    // Prepare common payload fields
    const payload = {
      visit_id: serviceOrder.visit_id, // Ensure this field exists in your serviceOrder data
      visit_comments: '',
      technician_info: user.email, // Assuming user.email is the technician's username
      // Additional fields will be added based on status
    };

    // Populate payload based on status
    switch (status) {
      case 'En route':
        payload.visit_status = 'En route';
        payload.visit_comments = 'The technician is on the way.';
        payload.estimated_time_arrival = new Date(Date.now() + 60 * 60 * 1000).toISOString(); // 1 hour from now
        payload.visitor_phone = '480-926-1975';
        break;

      case 'Check-in':
        try {
          const coords = await getCurrentGeolocation();
          setLocation(coords); // Update state with current location
          payload.visit_status = 'Check-in';
          payload.visit_comments = 'The Technician has arrived and started work.';
          payload.check_in_time = new Date().toISOString();
          payload.check_in_latitude = coords.latitude;
          payload.check_in_longitude = coords.longitude;
        } catch (error) {
          console.error('Geolocation error:', error);
          setLocationError('Unable to retrieve your location.');
          return;
        }
        break;

      case 'Check-out':
        try {
          const coords = await getCurrentGeolocation();
          setLocation(coords); // Update state with current location
          payload.visit_status = 'Check-out';
          payload.visit_comments = 'The Technician has completed their visit.';
          payload.check_out_time = new Date().toISOString();
          payload.check_out_latitude = coords.latitude;
          payload.check_out_longitude = coords.longitude;
        } catch (error) {
          console.error('Geolocation error:', error);
          setLocationError('Unable to retrieve your location.');
          return;
        }
        break;

      default:
        console.error(`Unsupported status: ${status}`);
        return;
    }

    try {
      const response = await fetch(
        'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_update_target_visit',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': API_KEY, // Ensure API_KEY is defined
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`API responded with status ${response.status}: ${errorText}`);
      }

      const data = await response.json();
      console.log('Target visit updated successfully:', data);

      // Optionally, update the appointment status in your state
      handleStatusChange(status); // Assuming this function exists to update status
    } catch (error) {
      console.error('Error updating target visit:', error);
      setError('Failed to update target visit. Please try again later.');
    }
  };

  /**
   * Handle Depart button click
   */
  const handleDepartClick = () => {
    updateTargetVisit('En route');
  };

  /**
   * Handle Arrive button click
   */
  const handleArriveClick = () => {
    updateTargetVisit('Check-in');
  };

  /**
   * Handle Complete button click
   */
  const handleCompleteClick = () => {
    updateTargetVisit('Check-out');
  };

  const [visitId, setVisitId] = useState(''); // State to store VisitID

  /**
   * Function to fetch service order details (including Invoice and Internal notes)
   * for the selected appointment
   */
  const fetchServiceOrderDetails = async () => {
    if (!selectedAppointment || !selectedAppointment.ServiceOrderID) {
      console.warn('No selected appointment to fetch service order details.');
      return;
    }

    setNotesLoading(true);
    setNotesError(null);

    try {
      // Remove any 'FS' prefix from the ServiceOrderID
      const serviceOrderID = selectedAppointment.ServiceOrderID.replace(/^FS/, '');

      const response = await fetch(GET_SERVICE_ORDERS_ENDPOINT, {
        method: 'POST', // Assuming POST is required by your API
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY, // Include the API key in headers
        },
        body: JSON.stringify({ serviceOrderIDs: [serviceOrderID] }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error(`API Error Response: ${errorText}`);
        throw new Error(`API responded with status ${response.status}`);
      }

      const data = await response.json();
      console.log('Fetched Service Order Details:', data);

      let serviceOrdersArray = [];

      if (data.body) {
        serviceOrdersArray = JSON.parse(data.body);
      } else if (data.data) {
        // In case Lambda returns data directly
        serviceOrdersArray = data.data;
      } else {
        // Handle unexpected response format
        throw new Error('Unexpected API response format');
      }

      if (Array.isArray(serviceOrdersArray) && serviceOrdersArray.length > 0) {
        const serviceOrder = serviceOrdersArray[0];

        // Extract the invoice notes and internal notes
        const invoiceNote = serviceOrder.note || '';
        const internalNote = serviceOrder.additionalFields?.UsrIntNotes || '';

        setInvoiceNotes(invoiceNote);
        setInternalNotes(internalNote);
      } else {
        console.warn('No service order details found for the selected appointment.');
        setInvoiceNotes('');
        setInternalNotes('');
        setNotesError('No service order details found.');
      }
    } catch (err) {
      console.error('Error fetching service order details:', err);
      setNotesError('Failed to load notes. Please try again later.');
    } finally {
      setNotesLoading(false);
    }
  };

  /**
   * UseEffect to fetch service order details when activeSection changes to 'notes'
   */
  useEffect(() => {
    if (activeSection === 'notes') {
      fetchServiceOrderDetails();
    }
  }, [activeSection, selectedAppointment]);

  /**
   * Function to parse Invoice Notes
   * @param {string} note - The raw note string
   * @returns {string} - The parsed and formatted invoice notes
   */
  const parseInvoiceNotes = (note) => {
    // Unescape the escaped newlines and backslashes
    const unescapedNote = note.replace(/\\n/g, '\n').replace(/\\\\/g, '\\');

    // Trim any white spaces
    const trimmedNote = unescapedNote.trim();

    return trimmedNote;
  };

  /**
   * Function to parse Internal Notes (HTML Content)
   * @param {string} htmlContent - The raw HTML content string
   * @returns {string} - The parsed and sanitized internal notes
   */
  const parseInternalNotes = (htmlContent) => {
    // Unescape any escaped characters
    const unescapedContent = htmlContent.replace(/\\n/g, '\n').replace(/\\r/g, '\r').replace(/\\\\/g, '\\');

    // Use DOMPurify to sanitize the HTML content
    const sanitizedHTML = DOMPurify.sanitize(unescapedContent);

    // Create a new DOM parser to extract text content
    const parser = new DOMParser();
    const doc = parser.parseFromString(sanitizedHTML, 'text/html');

    // Extract text content
    const textContent = doc.body ? doc.body.textContent : '';

    // Trim any white spaces
    const trimmedContent = textContent.trim();

    return trimmedContent;
  };

  return (
    <div className="AwF-AppointmentWorkFlow">
      {/* Toggle Button - Always visible */}
      {selectedAppointment && (
        <div className="awf-toggleButton" onClick={toggleSidebar}>
          <div className={`hamburger ${!collapsed ? 'open' : ''}`}></div>
        </div>
      )}

      {/* Overlay - Only visible when sidebar is open */}
      {selectedAppointment && (
        <div
          className={`awf-overlay ${!collapsed ? 'open' : ''}`}
          onClick={toggleSidebar}
        ></div>
      )}

      {/* Sidebar Container */}
      {selectedAppointment && (
        <div className={`awf-sidebarContainer ${!collapsed ? 'open' : ''}`}>
          <ProSidebar collapsed={collapsed} width="80px">
            <Menu iconShape="square">
              <MenuItem
                icon={<FaTruck size={32} />}
                active={activeSection === 'status'}
                onClick={() => handleMenuItemClick('status')}
              />
              <MenuItem
                icon={<FaRegStickyNote size={32} />}
                active={activeSection === 'notes'}
                onClick={() => handleMenuItemClick('notes')}
              />
              <MenuItem
                icon={<FaCamera size={32} />}
                active={activeSection === 'images'}
                onClick={() => handleMenuItemClick('images')}
              />
              <MenuItem
                icon={<FaTools size={32} />}
                active={activeSection === 'parts'}
                onClick={() => handleMenuItemClick('parts')}
              />
            </Menu>
          </ProSidebar>
        </div>
      )}

      {/* Main Content */}
      <div className="awf-content-spacing" onClick={() => setCollapsed(true)}>
        {/* Ensure clicking on the main content closes the menu */}
        <div className="awf-card">
          <div className="awf-cardHeader">
            <h2 className="awf-cardTitle">
              {selectedAppointment
                ? `Appointment ${selectedAppointment.ServiceOrderID}-${selectedAppointment.AppointmentNumber}`
                : 'Appointments'}
            </h2>
            {selectedAppointment && (
              <div className="awf-cardSubHeader">
                <div>{formatDate(selectedAppointment.AppointmentDate)}</div>
                <div>{selectedAppointment.Status}</div>
              </div>
            )}
          </div>
          <div className="awf-cardContent">
            {!selectedAppointment ? (
              // **Appointments List View**
              <div className="awf-currentAppointmentsContent">
                {renderAppointmentsListing()}
              </div>
            ) : (
              // **Appointment Detail View**
              <>
                <nav className="awf-breadcrumbs">
                  <span
                    className="awf-breadcrumb-link"
                    onClick={handleBackToList}
                    style={{
                      cursor: 'pointer',
                      color: '#007bff',
                      textDecoration: 'underline',
                    }}
                  >
                    Appointments
                  </span>
                  <span className="awf-breadcrumb-separator">/</span>
                  <span className="awf-breadcrumb-current">
                    {`${selectedAppointment.ServiceOrderID}-${selectedAppointment.AppointmentNumber}`}
                  </span>
                </nav>

                <div className="awf-appointmentDetailLayout">
                  {/* Sidebar is managed above */}

                  <div className="awf-mainContent">
                    {activeSection === 'status' && (
                      <>
                        {renderAppointmentDetails()}
                      </>
                    )}

                    {activeSection === 'notes' && renderNotesSection()}

                    {activeSection === 'images' && renderImagesSection()}

                    {activeSection === 'parts' && renderPartsSection()}
                  </div>
                </div>

                {/* Parts Modal */}
                {showPartsModal && renderAddPartsModal()}
              </>
            )}
          </div>
        </div>
      </div>

      {/* Render Hold Modal conditionally */}
      {showHoldModal && selectedAppointment && renderHoldModal()}

      {showCreatePOModal && renderCreatePOModal()}
    </div>
  );
}

  // CSS for highlighting current appointment items
  const styles = `
    .highlight {
      background-color: #e6f7ff; /* Light blue background for highlighted rows */
    }
  `;

  // Inject styles into the document
  const styleSheet = document.createElement("style");
  styleSheet.type = "text/css";
  styleSheet.innerText = styles;
  document.head.appendChild(styleSheet);

