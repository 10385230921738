import React, { createContext, useContext, useState } from 'react';

export const EncodeDecodeContext = createContext();

export const EncodeDecodeContextProvider = ({ children }) => {

    var encodeEntityMap = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#39;',
        '/': '&#x2F;',
        '`': '&#x60;',
        '=': '&#x3D;'
    };

    const encodeInput = (input) => {
        return String(input).replace(/[&<>"'`=\/]/g, (s) => {
            return encodeEntityMap[s];
        });
    }

    var decodeEntityMap = {
        '&amp;': '&',
        '&lt;': '<',
        '&gt;': '>',
        '&quot;': '"',
        '&#39;': "'",
        '&#x2F;': '/',
        '&#x60;': '`',
        '&#x3D;': '='
    };

    function decodeOutput(output) {
        return output.replace(/&amp;|&lt;|&gt;|&quot;|&#39;|&#x2F;|&#x60;|&#x3D;/g, function(match) {
            return decodeEntityMap[match];
        });
    }

  return (
    <EncodeDecodeContext.Provider value={{ encodeInput, decodeOutput }}>
      {children}
    </EncodeDecodeContext.Provider>
  );
};