// Loading.js
import React from 'react';
import './css/components.css'; // Import the CSS file for styling

const Loading = ({ componentName }) => {
  return (
    <div className="loading-container">
      <i className="fas fa-spinner fa-spin"></i>
      <p>Loading {componentName}...</p>
    </div>
  );
};

export default Loading;