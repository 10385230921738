   // src/context/ThemeContext.jsx
   import React, { createContext, useState, useEffect } from 'react';

   export const ThemeContext = createContext();

   export const ThemeProvider = ({ children }) => {
       const [theme, setTheme] = useState('bootstrap');

       useEffect(() => {
           const savedTheme = localStorage.getItem('theme');
           if (savedTheme) {
               setTheme(savedTheme);
           }
       }, []);

       useEffect(() => {
           localStorage.setItem('theme', theme);
           const themeLink = document.getElementById('theme-link');
           if (theme === 'darkly') {
               themeLink.href = 'https://cdn.jsdelivr.net/npm/bootswatch@5.3.0/dist/darkly/bootstrap.min.css';
           } else {
               themeLink.href = 'https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css';
           }
       }, [theme]);

       const toggleTheme = () => {
           setTheme(prevTheme => (prevTheme === 'bootstrap' ? 'darkly' : 'bootstrap'));
       };

       return (
           <ThemeContext.Provider value={{ theme, toggleTheme }}>
               {children}
           </ThemeContext.Provider>
       );
   };