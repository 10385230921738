import React, { useState, useContext } from 'react';
import './css/components.css';
import NoteEdit from './NoteEdit';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import UserContext from './context/UserContext';

export default function Note({ note, deleteNews, editNews }) {
    const { user } = useContext(UserContext);
    const { article_ID, body_text, title_text, created_by, created_date, last_editor, last_edit_date } = note;

    const [editing, setEditing] = useState(false);
    const [show, setShow] = useState(false);

    const handleEditing = () => setEditing(!editing);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleConfirm = () => {
        deleteNews(article_ID);
        handleClose();
    }

    return (
        <>
        <div className="note">
            {
                editing ?
                <NoteEdit note={note} handleEditing={handleEditing} editNews={editNews} /> :
                <>
                <h4 className='note__title'>{title_text}</h4>
                <div className="note__body">{body_text}</div>
                <hr className='note__separator'/>
                <div className="note__footer">
                    <div>
                        <span className="label note__written__by">Written by {created_by}
                        { last_editor && <><br />Last edited by {last_editor}</> }
                        </span>
                    </div>
                    <div>
                        {
                        user.role_id >= 8 &&
                            <>
                                <a onClick={handleEditing}>
                                    <i className="fa-solid fa-pen-to-square note__edit" aria-hidden="true"></i>
                                </a>
                                <a onClick={handleShow}>
                                    <i className="fa-solid fa-trash note__delete" aria-hidden="true"></i>
                                </a>
                            </>
                        }
                    </div>
                </div>
                </>
            }
        </div>
        {user.role_id >= 8 &&
        <Modal 
            show={show} 
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered
        >
            <Modal.Header>
                <Modal.Title>Please confirm:</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure you wish to delete <strong>{title_text}</strong>?</p>
                <p>This action cannot be undone.</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="danger"onClick={handleConfirm}>
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>}
    </>
    )
}