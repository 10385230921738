import React, { useState, useEffect, useContext, useDebugValue } from 'react';
import axios from 'axios';
import Loading from './Loading';
import TrainingTableRow from './TrainingTableRow';
import UserContext from './context/UserContext';
import TrainingTableAdd from './TrainingTableAdd';
import { EncodeDecodeContext } from './context/EncodeDecodeContext';

export default function TrainingTable() {
    const { user } = useContext(UserContext);
    const { decodeOutput } = useContext(EncodeDecodeContext);

    const [trainingList, setTrainingList] = useState(null);
    const [trainingCategories, setTrainingCategories] = useState(null);
    const [mediaTypes, setMediaTypes] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [sortProperties, setSortProperties] = useState({columnkey: "training_name", descending: false});

    const getFilteredAndSortedData = () => {
        if (!trainingList) return [];

        // Filter data
        const filteredData = trainingList.filter(item => {
            return Object.values(item).some(value =>
                value.toString().toLowerCase().includes(searchTerm.toLowerCase())
            );
        });

        // Sort data
        const { columnkey, descending } = sortProperties;
        const sortedData = filteredData.slice().sort((a, b) => {
            if (!columnkey) return 0;
            if (a[columnkey] < b[columnkey]) return descending ? 1 : -1;
            if (a[columnkey] > b[columnkey]) return descending ? -1 : 1;
            return 0;
        });
        //console.log(sortedData)

        return sortedData;
    };

    const sortedAndFilteredData = getFilteredAndSortedData();

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleSortChange = (e) => {
        const columnkey=e.target.getAttribute('columnkey');
        if (columnkey === sortProperties.columnkey) {
            setSortProperties(prevState => ({
                ...prevState,
                descending: !prevState.descending
            }))
        }
        else {
            setSortProperties({columnkey: columnkey, descending: false})
        }
    }

    const getSortSymbol = (columnkey) => {
        if (columnkey === sortProperties.columnkey) return sortProperties.descending ? "↓" : "↑";
        return null;
    }

    const refreshTrainingList = () => {
        setTrainingList(null);
    }

    useEffect(() => {
        if(trainingList === null) {
            axios.get(
                'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_list_training',
                { headers: { "x-api-key": '3XnwNPAWjV5bVyTEZ83u24cj3PRCA1zz44nifSQz' } }
            ).then(response => {
                    let tList = JSON.parse(response.data.body)
                    tList.forEach((item) => {
                        item.training_name = decodeOutput(item.training_name);
                        item.training_url = decodeOutput(item.training_url)
                    })
                    setTrainingList(tList);
                }
            )
            .catch((e) => console.log(e));
        }
    },[trainingList])

    useEffect(() => {
        if(trainingCategories === null) {
            axios.get(
                'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_list_training_categories',
                { headers: { "x-api-key": '3XnwNPAWjV5bVyTEZ83u24cj3PRCA1zz44nifSQz' } }
            ).then(response => {
                    setTrainingCategories(JSON.parse(response.data.body));
                    console.log(trainingCategories)
                }
            )
            .catch((e) => console.log(e));
        }
    },[trainingCategories])

    useEffect(() => {
        if(mediaTypes === null) {
            axios.get(
                'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_list_media_types',
                { headers: { "x-api-key": '3XnwNPAWjV5bVyTEZ83u24cj3PRCA1zz44nifSQz' } }
            ).then(response => {
                    setMediaTypes(JSON.parse(response.data.body));
                    console.log(mediaTypes)
                }
            )
            .catch((e) => console.log(e));
        }
    },[mediaTypes])

    return (
        <div>
        {
            trainingList === null ?
            <Loading componentName={"Training"} /> :
            <div>
                <div className='d-flex justify-content-between'>
                    <input type='text' placeholder='Search training...' value={searchTerm} onChange={handleSearchChange}/>
                    {
                        user.role_id >=8 ?
                            trainingCategories === null || mediaTypes === null ?
                            <i className="fas fa-spinner fa-spin"></i> :
                        <TrainingTableAdd 
                            refreshTrainingList={refreshTrainingList} 
                            trainingCategories={trainingCategories}
                            mediaTypes={mediaTypes}
                        /> :
                        <></>
                    }
                </div>
                <table className='table table-striped'>
                    <thead>
                        <tr>
                            <th columnkey="training_name" onClick={handleSortChange}>Training {getSortSymbol("training_name")}</th>
                            <th className="hidden__on__mobile" columnkey="training_category_name" onClick={handleSortChange}>Category {getSortSymbol("training_category_name")}</th>
                            <th className="text-center" columnkey="media_type_name" onClick={handleSortChange}>Type {getSortSymbol("media_type_name")}</th>
                            {
                                user.role_id >= 8 &&
                                <th className="text-center">Actions</th>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {sortedAndFilteredData.map((item, index) => (
                            <TrainingTableRow 
                                item={item} 
                                key={index} 
                                trainingCategories={trainingCategories}
                                mediaTypes={mediaTypes}
                                refreshTrainingList={refreshTrainingList}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
        }
        </div>
    )
}