import React from 'react';
import './css/components.css'; 

const Submitting = ({ displayText }) => {
  return (
    <div className="submitting-container">
      <i className="fas fa-spinner fa-spin"></i>
      <p>{displayText}...</p>
    </div>
  );
};

export default Submitting;