// src/utils/fetchTechnicians.js

/**
 * Fetches technicians with role_id 0 or 1 from ConexCS and retrieves their names.
 * Returns a list of matched technicians with user_id, email, and employeeName, sorted alphabetically by employeeName.
 */

export async function fetchAndMatchTechnicians() {
  const conexcsUsersEndpoint =
      'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_list_users';
  
  // API Key - Replace with your actual API key or use an environment variable
  const API_KEY = '3XnwNPAWjV5bVyTEZ83u24cj3PRCA1zz44nifSQz';

  try {
      // Fetch ConexCS users
      const conexcsResponse = await fetch(conexcsUsersEndpoint, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'x-api-key': API_KEY, // Include the API key here
          },
      });

      if (!conexcsResponse.ok) {
          const errorBody = await conexcsResponse.text();
          console.error(
              'Failed to fetch ConexCS users:',
              conexcsResponse.status,
              errorBody
          );
          throw new Error('Failed to fetch ConexCS users');
      }

      const conexcsData = await conexcsResponse.json();
      console.log('ConexCS Data:', conexcsData);

      let conexcsUsers;
      if (Array.isArray(conexcsData)) {
          // The response is already an array
          conexcsUsers = conexcsData;
      } else if (conexcsData.body) {
          // If the data is nested inside 'body', parse it
          try {
              conexcsUsers = JSON.parse(conexcsData.body);
          } catch (parseError) {
              console.error('Error parsing ConexCS users body:', parseError);
              throw new Error('Invalid JSON format in ConexCS users body');
          }
      } else {
          console.error(
              'Unexpected ConexCS users response structure:',
              conexcsData
          );
          throw new Error(
              'Unexpected response structure when fetching ConexCS users'
          );
      }

      console.log('ConexCS Users:', conexcsUsers);

      // Ensure conexcsUsers is an array
      if (!Array.isArray(conexcsUsers)) {
          throw new Error('ConexCS users data is not an array');
      }

      // Temporary: Include all users
      const technicians = conexcsUsers;
      
      // Original filtering logic (commented out for testing)
      /*
      const technicians = conexcsUsers.filter(
          (user) => user.role_id === 0 || user.role_id === 1
      );
      */

      console.log('Filtered Technicians:', technicians);

      const matchedTechnicians = technicians.map((tech) => ({
          user_id: tech.user_id,
          email: tech.user_name,
          employeeName: tech.name || 'N/A',
      }));

      const matchedTechniciansFiltered = matchedTechnicians.filter(
          (tech) => tech.employeeName !== 'N/A'
      );

      matchedTechniciansFiltered.sort((a, b) => a.employeeName.localeCompare(b.employeeName));

      console.log('Matched Technicians with Names (Sorted):', matchedTechniciansFiltered);

      return matchedTechniciansFiltered;
  } catch (error) {
      console.error('Error fetching technicians:', error);
      throw error;
  }
}