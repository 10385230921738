// src/components/AppointmentScreen.jsx

import './css/appointments.css';

import React, { useState, useEffect, useRef } from 'react';
import { useParams, useLocation, Link } from 'react-router-dom';
import { fetchAndMatchTechnicians } from '../utils/fetchTechnicians';
import { fetchAppointments } from '../utils/fetchAppointments';
import { cancelAppointment } from '../utils/cancelAppointment';
import { updateAppointmentStatus } from '../utils/updateAppointmentStatus';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Confetti from 'react-confetti'; // Import Confetti

export default function AppointmentScreen() {
  const { serviceOrderNumber } = useParams();
  const location = useLocation();
  const serviceOrderDetails = location.state?.serviceOrderDetails || {};

  const [appointments, setAppointments] = useState([]);
  const [technicians, setTechnicians] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedTechnician, setSelectedTechnician] = useState(null);
  const [loading, setLoading] = useState(false);
  const [appointmentsLoading, setAppointmentsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [appointmentToCancel, setAppointmentToCancel] = useState(null);
  const [technicianSearchTerm, setTechnicianSearchTerm] = useState('');
  const [filteredTechnicians, setFilteredTechnicians] = useState([]);
  const [selectedAppointmentDate, setSelectedAppointmentDate] = useState(() => {
    const today = new Date();
    return today.toISOString().split('T')[0]; // Format as YYYY-MM-DD
  });
  const [modalStep, setModalStep] = useState(1); // Step 1: Select Technician, Step 2: Select Date
  const [showConfetti, setShowConfetti] = useState(false); // State to control confetti display
  const [techNumber, setTechNumber] = useState(1); // New state for Tech number

  const modalRef = useRef(null); // Create a ref for the modal
  const [modalDimensions, setModalDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    if (modalRef.current) {
      setModalDimensions({
        width: modalRef.current.offsetWidth,
        height: modalRef.current.offsetHeight,
      });
    }
  }, [showModal, modalStep]); // Update dimensions when modal opens or step changes

  // **Configuration: Single API Key and Endpoint URLs**
  const API_KEY = '3XnwNPAWjV5bVyTEZ83u24cj3PRCA1zz44nifSQz'; // Shared API Key for both endpoints
  const CREATE_VISIT_ENDPOINT =
    'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_create_visit'; // Replace with your actual endpoint URL
  const CREATE_APPOINTMENT_ENDPOINT =
    'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_insert_appointment'; // Updated endpoint
  const UPDATE_TARGET_VISIT_ENDPOINT =
    'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_update_target_visit';
  // Fetch appointments for the serviceOrderNumber
  useEffect(() => {
    const getAppointments = async () => {
      setAppointmentsLoading(true);
      setError(null);

      try {
        const fetchedAppointments = await fetchAppointments(serviceOrderNumber);
        setAppointments(
          fetchedAppointments.map((appointment) => ({
            ...appointment,
            AppointmentDate: appointment.AppointmentDate || 'N/A', // Ensure AppointmentDate is set
          }))
        );
        setAppointmentsLoading(false);
      } catch (err) {
        console.error('Error fetching appointments:', err);
        setError('Failed to load appointments. Please try again later.');
        setAppointmentsLoading(false);
      }
    };

    getAppointments();
  }, [serviceOrderNumber]);

  /**
   * Handles the action of adding a new appointment.
   * Fetches available technicians and displays the selection modal.
   */
  const handleAddAppointment = () => {
    setModalStep(1); // Reset to the first step when starting a new appointment
    setShowModal(true);
  };

  /**
   * Sets the selected technician based on user selection.
   * @param {Object} technician - The technician object selected by the user.
   */
  const handleSelectTechnician = (technician) => {
    setSelectedTechnician(technician);
  };

  /**
   * Extracts the location ID from the customer name.
   * Assumes the location ID is the last 4 digits.
   * @returns {string|null} - The extracted location ID or null if not found.
   */
  const extractLocationId = () => {
    const customerName = serviceOrderDetails?.customerName || '';
    const locationIdMatch = customerName.match(/T(\d{4})$/);
    if (locationIdMatch && locationIdMatch[1]) {
      return locationIdMatch[1];
    }
    // Fallback: last 4 characters
    const lastFour = customerName.slice(-4);
    return /^\d{4}$/.test(lastFour) ? lastFour : null;
  };

  /**
   * Calculates the estimated time of arrival, which is always one hour from the current time.
   * @returns {string} - The estimated time of arrival in ISO format.
   */
  const getEstimatedTimeArrival = () => {
    const now = new Date();
    const estimatedTime = new Date(now.getTime() + 60 * 60 * 1000); // Add 1 hour
    return estimatedTime.toISOString();
  };

  /**
   * Fetches Visit ID from the Create Visit API and returns it.
   * Adds logging for the request and response.
   * @returns {string|null} - The fetched VisitID or null if not applicable.
   */
  const fetchVisitID = async () => {
    // Check if customer name includes "Target" and customer order is valid
    const customerName = serviceOrderDetails?.customerName || '';
    const customerOrder = serviceOrderDetails?.customerOrder || '';

    const isTargetCustomer = customerName.toLowerCase().includes('target');
    const isValidCustomerOrder =
      customerOrder &&
      customerOrder.trim() !== '' &&
      customerOrder.trim().toLowerCase() !== 'n/a';

    if (isTargetCustomer && isValidCustomerOrder) {
      const location_id = extractLocationId();
      if (!location_id) {
        console.error('Location ID could not be extracted from customer name:', customerName);
        setError('Invalid customer name format. Cannot extract location ID.');
        return null;
      }

      // **Send the request payload directly as expected by the API**
      const requestBody = {
        related_tranid: customerOrder.trim(),
        technician_info: selectedTechnician?.employeeName || 'N/A',
        estimated_time_arrival: getEstimatedTimeArrival(),
        location_id: location_id,
      };

      console.log('Sending request to CREATE_VISIT_ENDPOINT:', {
        url: CREATE_VISIT_ENDPOINT,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY,
        },
        body: requestBody,
      });

      try {
        const response = await fetch(CREATE_VISIT_ENDPOINT, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': API_KEY,
          },
          body: JSON.stringify(requestBody),
        });

        console.log('Received response from CREATE_VISIT_ENDPOINT:', {
          status: response.status,
          ok: response.ok,
        });

        const responseText = await response.text();
        console.log('Response body:', responseText);

        if (!response.ok) {
          console.error('Failed to fetch VisitID:', response.status, responseText);
          try {
            const errorData = JSON.parse(responseText);
            setError(`Failed to fetch VisitID: ${errorData.error || 'Unknown error'}`);
          } catch (parseError) {
            setError('Failed to fetch VisitID. Appointment created without VisitID.');
          }
          return null;
        }

        // Directly parse the responseText as JSON
        const data = JSON.parse(responseText);
        console.log('Parsed response data:', data);

        // Use the correct key 'visit_id' from the response
        if (data && typeof data.visit_id === 'number') {
          console.log('Fetched VisitID:', data.visit_id);
          return data.visit_id;
        } else {
          console.error('VisitID not found or invalid in response:', data);
          setError('VisitID not found or invalid in response. Appointment created without VisitID.');
          return null;
        }
      } catch (error) {
        console.error('Error fetching VisitID:', error);
        setError('Failed to fetch VisitID. Appointment created without VisitID.');
        return null;
      }
    }

    return null;
  };

  /**
   * Creates a new appointment by calling the backend API.
   * If the customer is a "Target" customer, it first creates a visit and includes the VisitID.
   * Otherwise, it creates an appointment without a VisitID.
   */
  const handleCreateAppointment = async () => {
    if (!selectedTechnician || !selectedAppointmentDate) {
      alert('Please select a technician and an appointment date.');
      return;
    }

    // Show confetti immediately when the button is clicked
    setShowConfetti(true);

    try {
      setLoading(true);

      const customerName = serviceOrderDetails?.customerName || '';
      const isTargetCustomer = customerName.toLowerCase().includes('target');

      let visitID = null;

      if (isTargetCustomer) {
        visitID = await fetchVisitID();
        if (visitID === null) {
          setError('Cannot create appointment without a valid VisitID.');
          setLoading(false);
          return;
        }
      }

      const requestBody = {
        ServiceOrderID: serviceOrderNumber,
        TechnicianEmail: selectedTechnician.email,
        AppointmentDate: selectedAppointmentDate,
        Tech: techNumber, // Include Tech number in the request
        ...(isTargetCustomer && { VisitID: visitID }), // Ensure VisitID is a number
      };

      console.log('Sending request to CREATE_APPOINTMENT_ENDPOINT:', {
        url: CREATE_APPOINTMENT_ENDPOINT,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY,
        },
        body: requestBody,
      });

      const response = await fetch(CREATE_APPOINTMENT_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY,
        },
        body: JSON.stringify(requestBody),
      });

      console.log('Received response from CREATE_APPOINTMENT_ENDPOINT:', {
        status: response.status,
        ok: response.ok,
      });

      const responseText = await response.text();
      console.log('Response body:', responseText);

      if (!response.ok) {
        console.error('Failed to create appointment:', response.status, responseText);
        try {
          const errorData = JSON.parse(responseText);
          setError(`Failed to create appointment: ${errorData.error || 'Unknown error'}`);
        } catch (parseError) {
          setError('Failed to create appointment.');
        }
        throw new Error('Failed to create appointment');
      }

      const data = JSON.parse(responseText);
      console.log('Create Appointment Response:', data);

      let parsedBody;
      try {
        parsedBody = JSON.parse(data.body);
        console.log('Parsed appointment data:', parsedBody);
      } catch (parseError) {
        console.error('Error parsing response body:', parseError);
        throw new Error('Invalid response format from Create Appointment API');
      }

      if (parsedBody && parsedBody.data) {
        const { AppointmentNumber, TechnicianEmail, Status } = parsedBody.data;

        setAppointments((prevAppointments) => [
          ...prevAppointments,
          {
            AppointmentNumber: AppointmentNumber,
            TechnicianName: selectedTechnician.employeeName,
            TechnicianEmail: TechnicianEmail,
            Tech: techNumber, // Store Tech number in the appointment
            VisitID: visitID || 'N/A',
            AppointmentDate: selectedAppointmentDate,
            UpdatedAt: new Date().toISOString(),
            Status: Status || 'Assigned',
            OnHold: 0, // Set OnHold to 0 (No) by default
          },
        ]);
      } else if (parsedBody && parsedBody.AppointmentNumber) {
        const { AppointmentNumber, TechnicianEmail, Status } = parsedBody;

        setAppointments((prevAppointments) => [
          ...prevAppointments,
          {
            AppointmentNumber: AppointmentNumber,
            TechnicianName: selectedTechnician.employeeName,
            TechnicianEmail: TechnicianEmail,
            Tech: techNumber, // Store Tech number in the appointment
            VisitID: visitID || 'N/A',
            AppointmentDate: selectedAppointmentDate,
            UpdatedAt: new Date().toISOString(),
            Status: Status || 'Assigned',
            OnHold: 0, // Set OnHold to 0 (No) by default
          },
        ]);
      } else {
        console.error('Missing "data" property in response:', parsedBody);
        throw new Error('Invalid response structure from Create Appointment API');
      }

      handleModalClose();
      setSuccess('Appointment created successfully.');

      setTimeout(() => setSuccess(null), 5000);
    } catch (error) {
      console.error('Error creating appointment:', error);
      setError(error.message || 'Failed to create appointment');
    } finally {
      setLoading(false);
      setTimeout(() => {
        setShowConfetti(false);
        handleModalClose();
      }, 5000);
    }
  };

  /**
   * Initiates the cancellation process for a selected appointment.
   * @param {Object} appointment - The appointment object to be canceled.
   */
  const handleCancelAppointment = (appointment) => {
    setAppointmentToCancel(appointment);
    setShowCancelModal(true);
  };

  /**
   * Confirms and processes the cancellation of an appointment.
   */
  const confirmCancelAppointment = async () => {
    if (!appointmentToCancel) return;

    try {
      setLoading(true);

      const customerName = serviceOrderDetails?.customerName || '';
      const isTargetCustomer = customerName.toLowerCase().includes('target');
      const visitID = appointmentToCancel.VisitID; // Assuming VisitID is stored in the appointment object

      await cancelAppointment(serviceOrderNumber, appointmentToCancel.AppointmentNumber, visitID, isTargetCustomer);

      // Update the appointments list
      setAppointments((prevAppointments) =>
        prevAppointments.map((appt) =>
          appt.AppointmentNumber === appointmentToCancel.AppointmentNumber
            ? { ...appt, Status: 'Canceled', UpdatedAt: new Date().toISOString() }
            : appt
        )
      );

      setShowCancelModal(false);
      setAppointmentToCancel(null);
      setSuccess('Appointment canceled successfully.');

      // Clear success message after 5 seconds
      setTimeout(() => setSuccess(null), 5000);
    } catch (error) {
      console.error('Error canceling appointment:', error);
      setError(error.message || 'Failed to cancel appointment');
    } finally {
      setLoading(false);
    }
  };

  /**
   * Handles the dispatching of an appointment by updating its status to "Dispatched".
   * @param {Object} appointment - The appointment object to be dispatched.
   */
  const handleDispatchAppointment = async (appointment) => {
    try {
      setLoading(true);
      await updateAppointmentStatus(serviceOrderNumber, appointment.AppointmentNumber, 'Dispatched');

      // Update the appointments list
      setAppointments((prevAppointments) =>
        prevAppointments.map((appt) =>
          appt.AppointmentNumber === appointment.AppointmentNumber
            ? { ...appt, Status: 'Dispatched', UpdatedAt: new Date().toISOString() }
            : appt
        )
      );

      setSuccess('Appointment dispatched successfully.');

      // Clear success message after 5 seconds
      setTimeout(() => setSuccess(null), 5000);
    } catch (error) {
      console.error('Error dispatching appointment:', error);
      setError(error.message || 'Failed to dispatch appointment');
    } finally {
      setLoading(false);
    }
  };

  /**
   * Formats a date string into MM/DD/YYYY format.
   * @param {string} dateString - The date string to format.
   * @returns {string} - The formatted date string.
   */
  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const dateObj = new Date(dateString);
    if (isNaN(dateObj)) return 'Invalid Date';
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getDate()).padStart(2, '0');
    const year = dateObj.getFullYear();
    return `${month}/${day}/${year}`;
  };

  useEffect(() => {
    const fetchTechnicians = async () => {
      try {
        const fetchedTechnicians = await fetchAndMatchTechnicians();
        setTechnicians(fetchedTechnicians);
      } catch (error) {
        console.error('Error fetching technicians:', error);
        setError('Failed to load technicians. Please try again later.');
      }
    };

    fetchTechnicians();
  }, []);

  useEffect(() => {
    const filterTechnicians = () => {
      if (!Array.isArray(technicians)) return [];

      return technicians.filter((tech) =>
        Object.values(tech).some((value) =>
          value.toString().toLowerCase().includes(technicianSearchTerm.toLowerCase())
        )
      );
    };

    setFilteredTechnicians(filterTechnicians());
  }, [technicians, technicianSearchTerm]);

  const handleTechnicianSearchChange = (e) => {
    setTechnicianSearchTerm(e.target.value);
  };

  const handleNextStep = () => {
    if (modalStep === 1 && selectedTechnician) {
      setModalStep(2);
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
    setModalStep(1); // Reset to first step when modal is closed
    setSelectedTechnician(null);
    setSelectedAppointmentDate(null);
  };

  return (
    <div className="awf-content-spacing">
      <div className="awf-card">
        <div className="awf-cardHeader">
          <h2 className="awf-cardTitle">Appointments for Service Order {serviceOrderDetails.serviceOrderNumber || 'N/A'}</h2>
          <div className="service-order-details" style={{ marginLeft: '0', marginTop: '10px' }}>
            <p style={{ marginBottom: '0' }}>
              <strong>Customer:</strong> {serviceOrderDetails.customerName || 'N/A'}
            </p>
            <p style={{ marginBottom: '0' }}>
              <strong>Description:</strong> {serviceOrderDetails.details || 'N/A'}
            </p>
            <p style={{ marginBottom: '0' }}>
              <strong>Customer Order:</strong> {serviceOrderDetails.customerOrder || 'N/A'}
            </p>
          </div>
        </div>
        <nav className="breadcrumb" style={{ marginBottom: '20px', fontSize: '16px' }}>
          <Link to="/ServiceOrderLookup" className="breadcrumb-link" style={{ textDecoration: 'none', color: '#007bff' }}>
            Service Order Lookup
          </Link>
          <span className="breadcrumb-separator" style={{ margin: '0 8px' }}>/</span>
          <span className="breadcrumb-current" style={{ color: '#6c757d' }}>Appointments</span>
        </nav>
        <div className="awf-cardContent">
          {appointmentsLoading && (
            <div className="awf-loading">
              <Spinner animation="border" role="status" size="sm"></Spinner>
              <span> Loading appointments...</span>
            </div>
          )}
          {error && <div className="awf-error">{error}</div>}
          {success && <div className="awf-success">{success}</div>}
          {!appointmentsLoading && !error && (
            <>
              {appointments.length > 0 ? (
                <table className="awf-table">
                  <thead>
                    <tr>
                      <th style={{ width: '50px' }}>#</th>
                      <th>Technician</th>
                      <th>Tech</th>
                      <th>Appt. Date</th>
                      <th>Status</th>
                      <th>Hold</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {appointments.map((appointment, index) => (
                      <tr key={index}>
                        <td>{appointment.AppointmentNumber}</td>
                        <td>{appointment.TechnicianName || 'N/A'}</td>
                        <td>{appointment.Tech}</td>
                        <td>{appointment.AppointmentDate ? formatDate(appointment.AppointmentDate) : 'N/A'}</td>
                        <td>{appointment.Status}</td>
                        <td style={{ color: appointment.OnHold === 0 ? 'green' : 'red' }}>
                          {appointment.OnHold === 0 ? 'No' : 'Yes'}
                        </td>
                        <td>
                          <button
                            className="awf-button awf-dispatchButton"
                            onClick={() => handleDispatchAppointment(appointment)}
                            disabled={appointment.Status.toLowerCase() !== 'assigned' || loading}
                            style={{
                              width: '110px',
                              textAlign: 'center',
                              backgroundColor:
                                appointment.Status.toLowerCase() === 'assigned' ? '#007bff' : '#6c757d',
                              cursor:
                                appointment.Status.toLowerCase() === 'assigned' ? 'pointer' : 'not-allowed',
                              marginRight: '8px',
                            }}
                          >
                            {appointment.Status.toLowerCase() === 'assigned' ? 'Dispatch' : 'Dispatched'}
                          </button>
                          <button
                            className="awf-button awf-cancelButton"
                            onClick={() => handleCancelAppointment(appointment)}
                            disabled={
                              appointment.Status.toLowerCase() === 'canceled' ||
                              appointment.Status.toLowerCase() === 'complete' ||
                              loading
                            }
                            style={
                              appointment.Status.toLowerCase() === 'canceled' ||
                              appointment.Status.toLowerCase() === 'complete'
                                ? {
                                    backgroundColor: '#6c757d',
                                    cursor: 'not-allowed',
                                  }
                                : {}
                            }
                          >
                            Cancel
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="awf-noResults">No appointments found for this service order.</div>
              )}
              <button
                className="awf-button awf-addButton"
                onClick={handleAddAppointment}
                disabled={loading}
              >
                {loading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{' '}
                    Loading...
                  </>
                ) : (
                  'Add Appointment'
                )}
              </button>

              {/* Modal for selecting technician */}
              <Modal
                ref={modalRef} // Attach the ref to the modal
                show={showModal}
                onHide={handleModalClose}
                size="lg"
                aria-labelledby="technician-selection-modal"
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title id="technician-selection-modal">
                    {modalStep === 1 ? 'Select Technician' : 'Select Appointment Date'}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ position: 'relative' }}>
                  {showConfetti && (
                    <div
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        pointerEvents: 'none', // Ensure confetti doesn't block interactions
                        overflow: 'hidden', // Ensure confetti is contained
                      }}
                    >
                      <Confetti
                        width={modalDimensions.width}
                        height={modalDimensions.height}
                        numberOfPieces={200} // Adjust the number of pieces as needed
                      />
                    </div>
                  )}
                  {modalStep === 1 ? (
                    <>
                      <input
                        type="text"
                        placeholder="Search technicians..."
                        value={technicianSearchTerm}
                        onChange={handleTechnicianSearchChange}
                        className="awf-searchInput"
                      />
                      {filteredTechnicians.length > 0 ? (
                        <ul className="awf-technician-list">
                          {filteredTechnicians.map((tech) => (
                            <li
                              key={tech.user_id}
                              className={`awf-technician-item ${
                                selectedTechnician && selectedTechnician.user_id === tech.user_id
                                  ? 'selected'
                                  : ''
                              }`}
                              onClick={() => handleSelectTechnician(tech)}
                            >
                              {tech.employeeName} ({tech.email})
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <div>No technicians available</div>
                      )}
                    </>
                  ) : (
                    <input
                      type="date"
                      value={selectedAppointmentDate || ''}
                      onChange={(e) => setSelectedAppointmentDate(e.target.value)}
                      className="awf-dateInput"
                    />
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <div style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                    <label htmlFor="techNumber" style={{ marginRight: '5px' }}>Tech #</label>
                    <input
                      id="techNumber"
                      type="number"
                      value={techNumber}
                      onChange={(e) => setTechNumber(parseInt(e.target.value, 10))}
                      className="awf-techInput"
                      style={{ width: '40px', textAlign: 'center' }} // Adjust width for two digits
                    />
                  </div>
                  <Button variant="secondary" onClick={handleModalClose}>
                    Cancel
                  </Button>
                  {modalStep === 1 ? (
                    <Button
                      variant="primary"
                      onClick={handleNextStep}
                      disabled={!selectedTechnician}
                    >
                      Next
                    </Button>
                  ) : (
                    <Button
                      variant="primary"
                      onClick={handleCreateAppointment}
                      disabled={!selectedAppointmentDate || loading}
                    >
                      {loading ? (
                        <>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />{' '}
                          Creating...
                        </>
                      ) : (
                        'Create Appointment'
                      )}
                    </Button>
                  )}
                </Modal.Footer>
              </Modal>

              {/* Cancel Confirmation Modal */}
              <Modal
                show={showCancelModal}
                onHide={() => setShowCancelModal(false)}
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title>Confirm Cancellation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Are you sure you want to cancel Appointment #{appointmentToCancel?.AppointmentNumber}?
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={() => setShowCancelModal(false)}>
                    No
                  </Button>
                  <Button
                    variant="danger"
                    onClick={confirmCancelAppointment}
                    disabled={loading}
                  >
                    {loading ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />{' '}
                        Canceling...
                      </>
                    ) : (
                      'Yes, Cancel Appointment'
                    )}
                  </Button>
                </Modal.Footer>
              </Modal>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
