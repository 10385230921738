import React from 'react';
import ITAdminUserTable from './ITAdminUserTable';
import ITAdminDashboardContainer from './ITAdminDashboardContainer';

export default function Admin() {
    return (
        <div>
            <h1>IT Admin</h1>
            <ITAdminUserTable />
            <hr />
            <ITAdminDashboardContainer />
        </div>
    )
}