import React, { useState } from 'react';
import './css/components.css';

export default function NoteEdit({ note, handleEditing, editNews }) {
    const { article_ID, body_text, title_text, created_by, created_date, last_editor, last_edit_date } = note;

    const [edit_title_text, setEdit_title_text] = useState((title_text));
    const [edit_body_text, setEdit_body_text] = useState((body_text));

    const edit_title_textHandler = (e) => {
        setEdit_title_text(e.target.value);
    };

    const edit_body_textHandler = (e) => {
        setEdit_body_text(e.target.value);
    };

    const handleConfirm = () => {
        editNews(article_ID, edit_title_text, edit_body_text, "testuser")
    }

    return (
        <>
            <input 
                placeholder='Enter title...' 
                value={edit_title_text}
                onChange={edit_title_textHandler}  
            />
            <textarea
                cols='10'
                rows='5'
                placeholder='Enter text...'
                
                value={edit_body_text}
                onChange={edit_body_textHandler}
            />
            <hr className='note__separator'/>
            <div className="note__footer">
                <span className="label note__written__by"> left</span>
                <div>
                    <a onClick={handleEditing}>
                        <i className="fa-solid fa-rotate-left note__cancel__edit"></i>
                    </a>
                    <a onClick={handleConfirm}>
                        <i className="fa-solid fa-check note__confirm__edit"></i>
                    </a>
                </div>
            </div>
        </>
    )
}