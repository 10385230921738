// ServiceOrderTable.jsx

import { useState, useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ServiceOrderContext from './context/ServiceOrderContext';

export default function ServiceOrderTable() {
  const { serviceOrders } = useContext(ServiceOrderContext);
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState('');
  const [sortProperties, setSortProperties] = useState({
    columnKey: 'serviceOrderNumber',
    descending: false,
  });

  const [filteredAndSortedData, setFilteredAndSortedData] = useState([]);

  useEffect(() => {
    const getFilteredAndSortedData = () => {
      console.log('--- ServiceOrderTable UseEffect Triggered ---');
      console.log('serviceOrders:', serviceOrders);
      console.log('searchTerm:', searchTerm);
      if (!Array.isArray(serviceOrders)) return [];

      // Filter data
      const filteredData = serviceOrders.filter((order) => {
        return Object.values(order).some((value) => {
          if (value === null || value === undefined) {
            return false;
          }
          return value.toString().toLowerCase().includes(searchTerm.toLowerCase());
        });
      });

      console.log('filteredData length:', filteredData.length);

      // Sort data
      const { columnKey, descending } = sortProperties;
      const sortedData = filteredData.slice().sort((a, b) => {
        if (!columnKey) return 0;
        const aValue = a[columnKey] || '';
        const bValue = b[columnKey] || '';

        // If sorting by 'date', compare Date objects
        if (columnKey === 'date') {
          const aDate = new Date(aValue);
          const bDate = new Date(bValue);
          if (aDate < bDate) return descending ? 1 : -1;
          if (aDate > bDate) return descending ? -1 : 1;
          return 0;
        }

        // Default sorting
        if (aValue < bValue) return descending ? 1 : -1;
        if (aValue > bValue) return descending ? -1 : 1;
        return 0;
      });

      console.log('sortedData length:', sortedData.length);

      return sortedData;
    };

    const data = getFilteredAndSortedData();
    setFilteredAndSortedData(data);
    console.log('filteredAndSortedData length:', data.length);
  }, [serviceOrders, searchTerm, sortProperties]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSortChange = (columnKey) => {
    if (columnKey === sortProperties.columnKey) {
      setSortProperties((prevState) => ({
        ...prevState,
        descending: !prevState.descending,
      }));
    } else {
      setSortProperties({ columnKey: columnKey, descending: false });
    }
  };

  const getSortSymbol = (columnKey) => {
    if (columnKey === sortProperties.columnKey)
      return sortProperties.descending ? '↓' : '↑';
    return null;
  };

  // Function to format the date as mm/dd/yyyy
  const formatDate = (dateString) => {
    console.log('Formatting date:', dateString);
    if (!dateString) return 'N/A';
    const dateObj = new Date(dateString);
    if (isNaN(dateObj)) return 'Invalid Date';
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getDate()).padStart(2, '0');
    const year = dateObj.getFullYear();
    return `${month}/${day}/${year}`;
  };

  const handleRowClick = (order) => {
    // Navigate to the desired route programmatically with state
    navigate(`/service-orders/${order.serviceOrderNumber}/appointments`, {
      state: { serviceOrderDetails: order },
    });
  };

  return (
    <div>
      <table className="awf-table">
        <thead>
          <tr>
            <th colSpan="5">
              <div
                className="awf-searchContainer"
                style={{ display: 'flex', justifyContent: 'flex-start' }}
              >
                <input
                  type="text"
                  placeholder="Search service orders..."
                  value={searchTerm}
                  onChange={handleSearchChange}
                  className="awf-searchInput"
                />
              </div>
            </th>
          </tr>
          <tr>
            <th onClick={() => handleSortChange('serviceOrderNumber')}>
              SO #{getSortSymbol('serviceOrderNumber')}
            </th>
            <th onClick={() => handleSortChange('customerName')}>
              Customer {getSortSymbol('customerName')}
            </th>
            <th onClick={() => handleSortChange('details')}>
              Service Order Description {getSortSymbol('details')}
            </th>
            <th onClick={() => handleSortChange('customerOrder')}>
              Customer Order {getSortSymbol('customerOrder')}
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredAndSortedData.map((order, index) => (
            <tr
              key={index}
              style={{ cursor: 'pointer' }}
              onClick={() => handleRowClick(order)}
            >
              <td>{order.serviceOrderNumber || 'N/A'}</td>
              <td>{order.customerName || 'N/A'}</td>
              <td>{order.details || 'N/A'}</td>
              <td>{order.customerOrder || ''}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
