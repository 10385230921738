import { createContext, useState, useEffect } from 'react';

const ServiceOrderContext = createContext();

export const ServiceOrderProvider = ({ children }) => {
  const [serviceOrders, setServiceOrders] = useState(() => {
    // Retrieve initial state from localStorage
    const savedOrders = localStorage.getItem('serviceOrders');
    return savedOrders ? JSON.parse(savedOrders) : [];
  });

  useEffect(() => {
    // Save serviceOrders to localStorage whenever it changes
    localStorage.setItem('serviceOrders', JSON.stringify(serviceOrders));
  }, [serviceOrders]);

  return (
    <ServiceOrderContext.Provider value={{ serviceOrders, setServiceOrders }}>
      {children}
    </ServiceOrderContext.Provider>
  );
};

export default ServiceOrderContext;
