import React, { useContext } from 'react';
import UserDetails from './UserDetails';
import News from './News';
import PhoneList from './PhoneList';
import TrainingTable from './TrainingTable';
import Information from './Information';
import LongContentExample from './LongContentExample';
import ITAdminDashboardContainer from './ITAdminDashboardContainer';
import ComponentSampler from './ComponentSampler';
import Loading from './Loading';
import UserContext from './context/UserContext';

export default function Home() {
    const { user } = useContext(UserContext);

    if (user === null) {
        return (
            <Loading componentName={"App"} />
        )
    } 
    /*else if (user.role_id === 0) {
        return (
            <div>
                <h1>Welcome!</h1>
                <p>You are a valid user with no permissions. Please contact an administrator to obtain permissions.</p>
            </div>
        )
    }*/
    else if (user.role_id >= 0) {
        return (
            <div>
                <News />
            </div>
        )
    }
    else {
        return (
            <div>
                <h1>Something broke!</h1>
                <p>Try relogging. If that doesn't work, contact an administrator.</p>
            </div>
        )
    }
}