import { createContext, useState, useEffect } from 'react';

const PartsContext = createContext();

export const PartsProvider = ({ children }) => {
  const [parts, setParts] = useState(() => {
    // Retrieve initial state from localStorage
    const savedParts = localStorage.getItem('parts');
    return savedParts ? JSON.parse(savedParts) : [];
  });

  useEffect(() => {
    // Save parts to localStorage whenever it changes
    localStorage.setItem('parts', JSON.stringify(parts));
  }, [parts]);

  return (
    <PartsContext.Provider value={{ parts, setParts }}>
      {children}
    </PartsContext.Provider>
  );
};

export default PartsContext;