import React from 'react';
import TrainingTable from './TrainingTable';

export default function Training () {
    return (
        <>
            <h1>Training</h1>
            <TrainingTable />
        </>
    )
}