import React, { useState, useEffect, useMemo } from 'react';
import "./css/components.css";

export default function ITAdminUserTableRow({ user, roleList, editFlag, handleEditList, deleteFlag, handleDeleteList }) {
    const [editedRoleID, setEditedRoleID] = useState(user.role_id);

    useEffect(() => {
        !editFlag && setEditedRoleID(user.role_id);
    }, [editFlag])

    const handleEdit = (e) => {
        const newRoleId = e.target.options[e.target.selectedIndex].dataset.id;
        handleEditList({user_id: user.user_id, role_id: newRoleId, shouldRemove: false});
        setEditedRoleID(newRoleId);
    }

    return (
        <tr className={deleteFlag ? "table-danger" : editFlag ? "table-warning" : ""}>
            <td>{user.user_id}</td>
            <td>{user.user_name}</td>
            <td>
                {
                    editFlag ? 
                    <select defaultValue={user.role_name} onChange={handleEdit}>
                        {roleList.map((role, index) => (
                            <option key={index} data-id={role.role_id}>{role.role_name}</option>
                        ))}
                    </select> :
                    user.role_name
                }
                </td>
            <td>
                {editedRoleID}
            </td>
            <td>
                
                <div className='text-center'>
                    <a className={deleteFlag ? "user-management-icon-disabled" : ""} onClick={() => {
                        if(deleteFlag) handleDeleteList(user.user_id);
                        handleEditList({user_id: user.user_id, role_id: editedRoleID, shouldRemove: editFlag});
                    }} style={{marginRight: 10 + 'px'}}>
                        {
                            editFlag ?
                            <i className="fa-solid fa-pen-to-square"></i> :
                            <i className="fa-regular fa-pen-to-square"></i>
                        }
                    </a>
                    <a className={editFlag ? "user-management-icon-disabled" : ""} onClick={() => {
                        if(editFlag) handleEditList({user_id: user.user_id, role_id: editedRoleID, shouldRemove: true});
                        handleDeleteList(user.user_id);
                    }}>
                        {
                            deleteFlag ?
                            <i className="fa-solid fa-trash-can"></i> :
                            <i className="fa-regular fa-trash-can"></i>
                        }
                    </a>
                </div>
                
            </td>
        </tr>
    )
    
}