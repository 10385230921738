// src/utils/cancelAppointment.js

export async function cancelAppointment(serviceOrderID, appointmentNumber, visitID, isTargetCustomer) {
    const cancelAppointmentEndpoint = 'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_cancel_appointment'; // Replace with your actual endpoint
    const updateTargetVisitEndpoint = 'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_update_target_visit'; // New endpoint
  
    const API_KEY = '3XnwNPAWjV5bVyTEZ83u24cj3PRCA1zz44nifSQz'; // Replace with your API Key
  
    try {
        if (isTargetCustomer && visitID) {
          // Call the update target visit endpoint first
          const updateResponse = await fetch(updateTargetVisitEndpoint, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'x-api-key': API_KEY,
            },
            body: JSON.stringify({
              visit_status: 'Cancelled',
              visit_comments: 'Visit cancelled.',
              visit_id: visitID,
            }),
          });

          if (!updateResponse.ok) {
            const errorBody = await updateResponse.text();
            console.error('Failed to update target visit:', updateResponse.status, errorBody);
            throw new Error('Failed to update target visit');
          }

          console.log('Target visit updated successfully');
        }

        // Proceed with the normal cancellation process
        const response = await fetch(cancelAppointmentEndpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': API_KEY,
          },
          body: JSON.stringify({
            ServiceOrderID: serviceOrderID,
            AppointmentNumber: appointmentNumber,
          }),
        });
    
        if (!response.ok) {
          const errorBody = await response.text();
          console.error('Failed to cancel appointment:', response.status, errorBody);
          throw new Error('Failed to cancel appointment');
        }
    
        const data = await response.json();
        console.log('Cancel Appointment Response:', data);
    
        // Return the response data
        return data;
      } catch (error) {
        console.error('Error cancelling appointment:', error);
        throw error;
      }
    }